import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { ProfileService } from "./profile.service";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(
    private translate: TranslateService,
    private profileService: ProfileService,
    private authService: AuthService,
  ) {
  }

  setLocalization(): void {
    if (this.authService.isAuthenticated()) {
      this.profileService.getAuthenticatedUser().subscribe(profileData => {
        this.translate.use(profileData.language === 'ENGLISH' ? 'en' : 'es');
        localStorage.setItem('gl', profileData.language === 'ENGLISH' ? 'en' : 'es');
      });
    } else {
      if (localStorage.getItem('gl')) {
        this.translate.use(localStorage.getItem('gl'));
      } else {
        localStorage.setItem('gl', 'en');
        this.translate.use('en');
      }
    }
  }

  saveLanguageSettings(locale: string): void {
    this.translate.use(locale);
    localStorage.setItem('gl', locale);
  }

  getLocalization(): string {
    return localStorage.getItem('gl') || 'en';
  }
}
