import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { GridDataIntervalEnum } from "../../grid-data-dashboard/grid-data.enum";
import {
  breakdown,
  consumptionChartOptions,
  consumptionDataMapping,
  consumptionLabelsMapping
} from "../../grid-data-dashboard/data";
import { environment } from "../../../environments/environment";
import { ChartDataSets, ChartType } from "chart.js";
import { Label, SingleDataSet } from "ng2-charts";

@Component({
  selector: 'app-load-demand-review-dialog',
  templateUrl: './load-demand-review-dialog.component.html',
  styleUrls: ['./load-demand-review-dialog.component.scss']
})
export class LoadDemandReviewDialogComponent implements OnInit {
  intervalSliderValue = GridDataIntervalEnum.DAILY;
  lat = 40.5274351;
  lng = -74.5156657;

  consumptionChartData: ChartDataSets[] = [];
  consumptionChartOptions: any = {};
  consumptionChartLabels: Label[] = [];
  consumptionChartType: ChartType = 'bar';

  breakdownChartLabels: Label[] = ['Residential', 'Commercial', 'Manufactures', 'Other'];
  breakdownChartData: SingleDataSet = [];
  breakdownChartType: ChartType = 'doughnut';
  breakdownChartOptions: any = { cutoutPercentage: 60 };
  breakdownChartColors: any = [
    {
      backgroundColor: ['#9840DD', '#007AFF', '#e7884f', '#df4a4f'],
      borderColor: ['#9840DD', '#007AFF', '#e7884f', '#df4a4f'],
      borderWidth: 1
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<LoadDemandReviewDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    this.consumptionChartOptions = { ...consumptionChartOptions };
    this.consumptionChartData = consumptionDataMapping[this.intervalSliderValue];
    this.consumptionChartLabels = consumptionLabelsMapping[this.intervalSliderValue];

    this.breakdownChartData = breakdown;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  changeDataByInterval(interval: number): void {
    this.intervalSliderValue = interval;
    this.consumptionChartData = consumptionDataMapping[this.intervalSliderValue];
    this.consumptionChartLabels = consumptionLabelsMapping[this.intervalSliderValue];
  }

  changeDataByItem(item: number = null): void {
    if (item != null) {
      this.consumptionChartData = [ consumptionDataMapping[this.intervalSliderValue][item] ];
    } else {
      this.consumptionChartData = consumptionDataMapping[this.intervalSliderValue];
    }
  }

  getLocationStaticMap(): string {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${this.lat},${this.lng}&zoom=16&size=345x150
      &markers=icon:${location.origin}/assets/marker-icon.png&key=${environment.firebase.apiKey}`;
  }

}
