import { Component, OnInit } from '@angular/core';
import { Router}  from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { UserPreferencesDialogComponent } from "../dialogs/user-preferences-dialog/user-preferences-dialog.component";
import { SimulationService } from "../services/simulation.service";
import { OldSimulation } from "../models/oldSimulation";

@Component({
  selector: 'app-simulations',
  templateUrl: './simulations.component.html',
  styleUrls: ['./simulations.component.scss']
})
export class SimulationsComponent implements OnInit {
  simulations: OldSimulation[];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private simulationService: SimulationService,
  ) {
  }

  ngOnInit(): void {
    this.simulationService.getSimulationsList()
    // .subscribe(simulations =>
    //   this.simulations = simulations
    // );
  }

  openSimulation(simulationId = ''): void {
    this.router.navigate(['run-program'], { queryParams: { simId: simulationId } });
  }

  openUserPreferencesDialog(): void {
    this.dialog.open(UserPreferencesDialogComponent, {
      width: '500px',
    });
  }

}
