<div class="what-next-container">
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
  <div class="what-next-title">
    What’s next?
  </div>
  <div class="what-next-info">
    This is your Project Progress.<br>
    Let’s start building each step of your <br> microgrid project.
  </div>
</div>
