import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-what-next-dialog',
  templateUrl: './what-next-dialog.component.html',
  styleUrls: ['./what-next-dialog.component.scss']
})
export class WhatNextDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<WhatNextDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
