import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { OldSimulation } from "../models/oldSimulation";
import { SimulationService } from "../services/simulation.service";

@Component({
  selector: 'app-shared-simulation',
  templateUrl: './shared-simulation.component.html',
  styleUrls: ['./shared-simulation.component.scss']
})
export class SharedSimulationComponent implements OnInit {
  simulation: OldSimulation;
  error = false;

  constructor(
    private route: ActivatedRoute,
    private simulationService: SimulationService,
  ) {
  }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token');
    this.simulationService.getSimByShareTokenOLD(token).subscribe(
      simulation => {
        this.simulation = simulation;
        this.error = false;
      },
      () => {
        this.error = true;
      }
    );
  }

}
