import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { validateEmail } from "../../utils";
import {MessageDialogComponent} from "../message-dialog/message-dialog.component";

@Component({
  selector: 'app-reach-us-dialog',
  templateUrl: './reach-us-dialog.component.html',
  styleUrls: ['./reach-us-dialog.component.scss']
})
export class ReachUsDialogComponent implements OnInit {
  reachUsForm!: FormGroup;
  error = '';

  constructor(
    public dialogRef: MatDialogRef<ReachUsDialogComponent>,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.reachUsForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, validateEmail()]],
      message: ['', [Validators.required]],
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  resetError(): void {
    this.error = '';
  }

  sendMessage(): void {
    this.reachUsForm.markAllAsTouched();

    if (this.reachUsForm.valid) {
      this.matDialog.open(MessageDialogComponent, {
        panelClass: ['coreDialog'],
        width: 'auto',
        data: {
          onConfirm: () => {
            this.dialogRef.close();
          }
        }
      });
    }
  }

}
