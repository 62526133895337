import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MouseEvent } from '@agm/core';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent implements OnInit {
  lat = 51.678418;
  lng = 7.809007;
  zoom: number = 14;
  markers: { lat: number, lng: number, label?: string }[] = [];
  error = false;

  constructor(
    public matDialogRef: MatDialogRef<LocationDialogComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.matDialogRef.close();
  }

  mapClicked($event: MouseEvent) {
    if (this.markers.length === 2) {
      this.markers.shift();
      this.markers[0].label = '1';
      this.error = false;
    }
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      label: String(this.markers.length + 1),
    });
  }

}
