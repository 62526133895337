<div class="run-program-wrapper">
  <div class="run-program-container">
    <div class="simulation-header-block">
      <h2 class="run-program-title">Simulation Run Parameters</h2>
      <button mat-flat-button class="share-button" *ngIf="initialSimulationId" (click)="openShareDialog()">
        Share
        <mat-icon>share</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <form *ngIf="parametersForm" [formGroup]="parametersForm">
      <div class="simulation-name-container">
        <label for="name">Simulation Name</label>
        <mat-form-field id="name" appearance="outline">
          <input matInput formControlName="name" required placeholder="Enter simulation name">
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div class="simulation-time-container">
        <div class="time-column">
          <label for="startDate">Enter a start date</label>
          <mat-form-field id="startDate" appearance="outline">
            <input
              matInput
              [matDatepicker]="startPicker"
              (dateInput)="onDateInput('startTime', $event)"
              (dateChange)="onDateInput('startTime', $event)"
              [(ngModel)]="selectedStartDate"
              [ngModelOptions]="{standalone: true}"
              required
              placeholder="Enter a start date"
            >
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <label for="startTime">Enter a start time</label>
          <mat-form-field id="startTime" appearance="outline">
            <mat-select
              [disabled]="!parametersForm.value.startTime"
              (selectionChange)="onTimeInput('startTime', $event)"
              [(ngModel)]="selectedStartTime"
              [ngModelOptions]="{standalone: true}"
              required
              placeholder="Enter a start time"
            >
              <mat-option *ngFor="let hour of hours; index as index" [value]="index + 1">
                {{hour}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="time-column">
          <label for="endDate">Enter an end date</label>
          <mat-form-field id="endDate" appearance="outline">
            <input
              matInput
              [matDatepicker]="endPicker"
              (dateInput)="onDateInput('endTime', $event)"
              (dateChange)="onDateInput('endTime', $event)"
              [(ngModel)]="selectedEndDate"
              [ngModelOptions]="{standalone: true}"
              required
              placeholder="Enter an end date"
            >
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
          <label for="endTime">Enter an end time</label>
          <mat-form-field id="endTime" appearance="outline">
            <mat-select
              [disabled]="!parametersForm.value.startTime"
              (selectionChange)="onTimeInput('endTime', $event)"
              [(ngModel)]="selectedEndTime"
              [ngModelOptions]="{standalone: true}"
              required
              placeholder="Enter an end time"
            >
              <mat-option *ngFor="let hour of hours; index as index" [value]="index + 1">
                {{hour}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="consumers-amount-container">
        <div class="amount-container">
          <label for="consumers">Amount of consumers (5-40)</label>
          <mat-form-field id="consumers" appearance="outline">
            <input
              matInput
              formControlName="consumers"
              required
              placeholder="Enter an amount of consumers"
            >
          </mat-form-field>
        </div>
        <div class="region-container">
          <label for="region">Region</label>
          <mat-form-field id="region" appearance="outline">
            <input
              matInput
              formControlName="region"
              required
              placeholder="Enter a region"
            >
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="time-interval-container">
        <label for="timeInterval">Time interval</label>
        <mat-form-field id="timeInterval" appearance="outline">
          <input
            matInput
            formControlName="timeInterval"
            required
            placeholder="Enter a time interval"
          >
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div class="interval-length-container">
        <label for="timePeriod">Simulation Interval Length</label>
        <mat-form-field id="timePeriod" appearance="outline">
          <mat-select formControlName="timePeriod" required placeholder="Select">
            <mat-option value="1h">
              Hourly
            </mat-option>
            <mat-option value="1d">
              Daily
            </mat-option>
            <mat-option value="1w">
              Weekly
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="submit-container">
        <button mat-button routerLink="/simulations">Cancel</button>
        <button mat-flat-button color="primary" (click)="onSubmit()">Submit</button>
        <button mat-flat-button color="warn" *ngIf="initialSimulationId" (click)="openDeleteDialog()">Delete</button>
      </div>
    </form>
  </div>

  <div class="save-later-container">
    <p>Save for use later?</p>
    <button mat-flat-button class="save-button" type="submit">Yes</button>
    <button mat-flat-button color="primary" class="no-save-button" type="submit">No</button>
  </div>
</div>
