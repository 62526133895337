import { AbstractControl, ValidatorFn } from "@angular/forms";
import { SimRevision } from "../models/simulation";
import { GridDataIntervalEnum } from "../grid-data-dashboard/grid-data.enum";

export function  validateEmail(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value.match(/\S+@\S+\.\S+/) || !control.value ? null : { invalidEmail: true };
}

export function emailMatchValidator(confirmEmailInput: string): ValidatorFn {
  let confirmEmailControl: AbstractControl;
  let emailControl: AbstractControl;

  return (control: AbstractControl) => {
    if (!control.parent) {
      return null;
    }

    if (!confirmEmailControl) {
      confirmEmailControl = control;
      emailControl = control.parent.get(confirmEmailInput) as AbstractControl;
      emailControl.valueChanges.subscribe(() => {
        confirmEmailControl.updateValueAndValidity();
      });
    }

    if (
      emailControl.value.toLocaleLowerCase() !==
      confirmEmailControl.value.toLocaleLowerCase()
    ) {
      return {
        notMatch: true
      };
    }
    return null;
  };
}

export function getConsumptionDataMapping(simRevision: SimRevision, intervalValue: GridDataIntervalEnum) {
  const consumptionDataMapping = {
    [GridDataIntervalEnum.HOURLY]: [
      { data: simRevision.output.usage.hour.COMMERCIAL?.data.map(data => data.energy.consumed), label: 'Commercial', stack: 'a', borderColor: '#007AFF', backgroundColor: '#007AFF', hoverBackgroundColor: '#007AFF', hoverBorderColor: '#007AFF' },
      { data: simRevision.output.usage.hour.RESIDENTIAL?.data.map(data => data.energy.consumed), label: 'Residential', stack: 'a', borderColor: '#9840DD', backgroundColor: '#9840DD', hoverBackgroundColor: '#9840DD', hoverBorderColor: '#9840DD' },
      { data: simRevision.output.usage.hour.ANCHOR?.data.map(data => data.energy.consumed), label: 'Anchor', stack: 'a', borderColor: '#e7884f', backgroundColor: '#e7884f', hoverBackgroundColor: '#e7884f', hoverBorderColor: '#e7884f' },
      { data: simRevision.output.usage.hour.INDUSTRIAL?.data.map(data => data.energy.consumed), label: 'Industrial', stack: 'a', borderColor: '#df4a4f', backgroundColor: '#df4a4f', hoverBackgroundColor: '#df4a4f', hoverBorderColor: '#df4a4f' },
    ],
    [GridDataIntervalEnum.DAILY]: [
      { data: simRevision.output.usage.day.COMMERCIAL?.data.map(data => data.energy.consumed), label: 'Commercial', stack: 'a', borderColor: '#007AFF', backgroundColor: '#007AFF', hoverBackgroundColor: '#007AFF', hoverBorderColor: '#007AFF' },
      { data: simRevision.output.usage.day.RESIDENTIAL?.data.map(data => data.energy.consumed), label: 'Residential', stack: 'a', borderColor: '#9840DD', backgroundColor: '#9840DD', hoverBackgroundColor: '#9840DD', hoverBorderColor: '#9840DD' },
      { data: simRevision.output.usage.day.ANCHOR?.data.map(data => data.energy.consumed), label: 'Anchor', stack: 'a', borderColor: '#e7884f', backgroundColor: '#e7884f', hoverBackgroundColor: '#e7884f', hoverBorderColor: '#e7884f' },
      { data: simRevision.output.usage.day.INDUSTRIAL?.data.map(data => data.energy.consumed), label: 'Industrial', stack: 'a', borderColor: '#df4a4f', backgroundColor: '#df4a4f', hoverBackgroundColor: '#df4a4f', hoverBorderColor: '#df4a4f' },
    ],
    [GridDataIntervalEnum.MONTHLY]: [
      { data: simRevision.output.usage.month.COMMERCIAL?.data.map(data => data.energy.consumed), label: 'Commercial', stack: 'a', borderColor: '#007AFF', backgroundColor: '#007AFF', hoverBackgroundColor: '#007AFF', hoverBorderColor: '#007AFF' },
      { data: simRevision.output.usage.month.RESIDENTIAL?.data.map(data => data.energy.consumed), label: 'Residential', stack: 'a', borderColor: '#9840DD', backgroundColor: '#9840DD', hoverBackgroundColor: '#9840DD', hoverBorderColor: '#9840DD' },
      { data: simRevision.output.usage.month.ANCHOR?.data.map(data => data.energy.consumed), label: 'Anchor', stack: 'a', borderColor: '#e7884f', backgroundColor: '#e7884f', hoverBackgroundColor: '#e7884f', hoverBorderColor: '#e7884f' },
      { data: simRevision.output.usage.month.INDUSTRIAL?.data.map(data => data.energy.consumed), label: 'Industrial', stack: 'a', borderColor: '#df4a4f', backgroundColor: '#df4a4f', hoverBackgroundColor: '#df4a4f', hoverBorderColor: '#df4a4f' },
    ],
  };
  return consumptionDataMapping[intervalValue].filter(consDataByType => !!consDataByType.data);
}
