<div class="load-demand-review-dialog-header">
  <div class="dialog-title">
    PREVIEW MODE<br>
    <span>You are previewing how your guest will see the final results of load demand</span>
  </div>
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
</div>

<div class="load-demand-preview-header">
  <div class="load-demand-preview-header-title">
    <span class="preview-header">Community Microgrid Consumption Profile</span>
    <span class="company-name">DAO Farm</span>
    <div class="logo-button">
      <img
        src="../../../assets/LOGO.png"
        class="logo-button-title"
        alt="logo"
      />
      <div class="add-circle-container">
        <img
          src="../../../assets/add-circle.png"
          class="add-icon"
          alt="add_logo"
        />
      </div>
    </div>
  </div>
  <mat-divider class="header-divider"></mat-divider>
  <div class="load-demand-preview-header-actions">
    <button mat-button class="download-button">
      <mat-icon>file_download</mat-icon>&nbsp;
      Download
    </button>
    <button mat-button class="print-button">
      <mat-icon>print</mat-icon>&nbsp;
      Print
    </button>
    <button mat-button class="share-button">
      <mat-icon>share</mat-icon>&nbsp;
      Share to show result
    </button>
  </div>
</div>
<div class="load-demand-review-dialog-container">
  <div class="load-demand-left-column">
    <div class="block-row">
      <div class="block-title">Simulation Parameters</div>
      <div class="region-block">
        Region:&nbsp;
        <span>Caguas, Puerto Rico</span>
      </div>
    </div>
    <mat-divider class="title-line" [vertical]="true"></mat-divider>
    <div class="block-row">
      <div class="block-column">
          <span class="param-title">
            Average timeframe consumption
          </span>
        <span class="param-value">
            11,400 kWh / 500 kW
          </span>

        <span class="param-title">
            Peak consumption level
          </span>
        <span class="param-value">
            900 kW
          </span>
      </div>
      <div class="block-column">
          <span class="param-title">
            Timeframe
          </span>
        <span class="param-value">
            1 Week
          </span>

        <span class="param-title">
            Total Consumers
          </span>
        <span class="param-value">
            30
          </span>
      </div>
    </div>
    <mat-divider class="h-line" [vertical]="true"></mat-divider>
    <div class="block-row">
      <div class="block-title">Consumption levels (kWh)</div>
      <div class="interval-block">
        <button
          mat-button
          class="time-button"
          [ngClass]="{'active': intervalSliderValue === 0}"
          (click)="changeDataByInterval(0)"
        >
          Hourly
        </button>
        <button
          mat-button
          class="time-button"
          [ngClass]="{'active': intervalSliderValue === 50}"
          (click)="changeDataByInterval(50)"
        >
          Daily
        </button>
        <button
          mat-button
          class="time-button"
          [ngClass]="{'active': intervalSliderValue === 100}"
          (click)="changeDataByInterval(100)"
        >
          Monthly
        </button>
      </div>
    </div>
    <mat-divider class="title-line" [vertical]="true"></mat-divider>
    <div class="barchart-container">
      <canvas class="modelingChart"
              baseChart width="500" height="300"
              [datasets]="consumptionChartData"
              [labels]="consumptionChartLabels"
              [options]="consumptionChartOptions"
              [legend]="true"
              [chartType]="consumptionChartType"
      >
      </canvas>
    </div>
    <div class="legend-container">
      <button mat-button class="data-button" (click)="changeDataByItem(1)">
        <mat-icon class="residential-icon">circle</mat-icon>
        Residential
      </button>
      <button mat-button class="data-button" (click)="changeDataByItem(0)">
        <mat-icon class="commercial-icon">circle</mat-icon>
        Commercial
      </button>
      <button mat-button class="all-data-button" (click)="changeDataByItem()">CLICK TO SEE ALL</button>
    </div>
  </div>
  <mat-divider class="v-line" [vertical]="true"></mat-divider>
  <div class="load-demand-right-column">
    <div class="block-row">
      <div class="block-title">Breakdown by category</div>
    </div>
    <div class="breakdown-chart">
      <canvas
        baseChart
        width="200"
        height="200"
        [data]="breakdownChartData"
        [labels]="breakdownChartLabels"
        [chartType]="breakdownChartType"
        [legend]="false"
        [colors]="breakdownChartColors"
        [options]="breakdownChartOptions"
      >
      </canvas>
    </div>
    <div class="legend-container">
      <mat-icon class="residential-icon">circle</mat-icon>
      <div class="legend-item">
        <div class="legend-item-title">Residential</div>
        <div class="legend-item-value">8 000 kWh</div>
      </div>
      <mat-icon class="commercial-icon">circle</mat-icon>
      <div class="legend-item">
        <div class="legend-item-title">Commercial</div>
        <div class="legend-item-value">11 000 kWh</div>
      </div>
    </div>
    <div class="location-container">
      <div class="img-container">
        <img
          [src]="getLocationStaticMap()"
          class="report-icon"
          alt="report_icon"
        />
      </div>
      <div class="place-name">DAO Corp.</div>
      <div class="place-address">
        <span>PR 322 km 12.4, Tallaboa, PR 12345</span>
      </div>
    </div>
  </div>
</div>

<div class="sign-container">
  <span class="sign-text">
    Empowered by
  </span>
  <img
    src="../../../assets/h-fullcolor-1.png"
    class="logo"
    alt="logo"
  />
</div>
