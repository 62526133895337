export enum GridDataIntervalEnum {
  HOURLY = 0,
  DAILY = 50,
  MONTHLY = 100,
}

export const gridDataIntervalsMapping = {
  [GridDataIntervalEnum.HOURLY]: 'Hourly',
  [GridDataIntervalEnum.DAILY]: 'Daily',
  [GridDataIntervalEnum.MONTHLY]: 'Monthly',
};
