<div class="contact-dialog-container">
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
  <div class="contact-dialog-title">
    <mat-icon class="mail-icon">email_outline</mat-icon>
    Contact via the app
  </div>
  <div class="contact-dialog-form">
    Click Send to connect with this trusted professional about your community microgrid project.
    This professional will see this page as well as your contact information.
  </div>
  <div class="contact-dialog-actions">
    <button mat-button class="cancel-button" (click)="closeDialog()">Cancel</button>
    <button mat-button class="send-button" (click)="closeDialog()">Send</button>
  </div>
</div>
