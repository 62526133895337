<div class="account-settings-wrapper">

  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Account">
      <div class="account-container">
        <div class="company-logo-row">
          <div class="logo-button" (click)="openLogoUploaderDialog()">
            <img
              src="assets/LOGO.png"
              class="logo-button-title"
              alt="logo"
              *ngIf="!companyLogo"
            />
            <img
              [src]="companyLogo"
              class="company-logo"
              alt="logo"
              *ngIf="companyLogo"
            />
            <div class="add-circle-container">
              <mat-icon class="add-icon material-icons material-icons-two-tone">add_circle_outline</mat-icon>
            </div>
          </div>
          <div class="company-name-column">
            {{this.profile.company.contact.name}}<br>
            <span>Upload company logo</span>
          </div>
        </div>

        <form [formGroup]="accountForm" class="account-form">
          <div class="account-form-column">
            <div class="field-name">
              Name
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="name">
            </mat-form-field>
            <div class="field-name">
              Email address
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="email">
            </mat-form-field>
          </div>
          <div class="account-form-column">
            <div class="field-name">
              Location
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="address">
            </mat-form-field>
            <div class="field-name">
              Phone
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="phone">
            </mat-form-field>
          </div>
          <div class="account-form-column">
            <div class="field-name">
              Website
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="website">
            </mat-form-field>
          </div>
        </form>

        <form [formGroup]="companyForm" class="account-form">
          <div class="account-form-column">
            <div class="field-name">
              Company name
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="name">
            </mat-form-field>
            <div class="field-name">
              Company email address
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="email">
            </mat-form-field>
            <div class="field-name">
              Company bio
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <textarea matInput formControlName="bio"></textarea>
            </mat-form-field>
          </div>
          <div class="account-form-column">
            <div class="field-name">
              Company website
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="website">
            </mat-form-field>
            <div class="field-name">
              Company Location
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="address">
            </mat-form-field>
            <div class="field-name">
              Company Phone
            </div>
            <mat-form-field appearance="outline" class="account-field">
              <input matInput formControlName="phone">
            </mat-form-field>
          </div>
        </form>
        <button mat-button class="save-button" (click)="saveProfileChanges()">Save changes</button>
      </div>
    </mat-tab>
    <mat-tab label="Language">
      <div class="language-container">
        PREFERRED LANGUAGE
        <div class="language-slider-container">
          ES - Español
          <mat-slide-toggle class="language-slider" (change)="saveLanguage($event)" [checked]="profile.language === 'ENGLISH'">
          </mat-slide-toggle>
          EN - English
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Notifications" [formGroup]="accountPreferencesForm">
      <div class="notifications-container">
        <div class="pause-notifications-block">
          <mat-slide-toggle formControlName="isNotifyPaused" (change)="savePreferences()"></mat-slide-toggle>
          Pause notifications
        </div>
        <mat-divider class="h-line"></mat-divider>
        <div class="email-alerts-title">Email alerts</div>
        <div class="email-alerts-block">
          <mat-slide-toggle formControlName="isNotifyEmail" (change)="savePreferences()"></mat-slide-toggle>
          <div class="email-alerts-info">
            Receive email alerts at:<br>
            <span>company.name@gmail.com</span>
          </div>
        </div>
      </div>
      <div class="alerts-container">
        <span *ngIf="!receiveAlertsExpanded">LOGINS</span>
        <div class="receive-alerts-header">
          <div class="shield-icon-container">
            <mat-icon class="shield-icon">shield</mat-icon>
            <mat-icon class="done-icon">done</mat-icon>
          </div>
          Receive alerts about unrecognized logins
          <mat-icon
            class="arrow-icon"
            *ngIf="!receiveAlertsExpanded"
            (click)="receiveAlertsExpanded = true"
          >
            expand_more
          </mat-icon>
          <mat-icon
            class="arrow-icon"
            *ngIf="receiveAlertsExpanded"
            (click)="receiveAlertsExpanded = false"
          >
            expand_less
          </mat-icon>
        </div>
        <div class="email-alerts-block" *ngIf="receiveAlertsExpanded">
          <mat-slide-toggle formControlName="isAlertUnknownLogin" (change)="savePreferences()"></mat-slide-toggle>
          <div class="email-alerts-info">
            Receive email alerts at:<br>
            <span>company.name@gmail.com</span>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
