<div class="simulation-review-dialog-header">
  <div class="dialog-title">
    PREVIEW MODE<br>
    <span>You are previewing how your guest will see the final results of load demand</span>
  </div>
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
</div>

<div class="simulation-preview-header">
  <div class="simulation-preview-header-title">
    <span class="preview-header">MICROGRID PROJECT</span>
    <mat-icon>chevron_right</mat-icon>
    <span class="preview-header">OPERATIONS SIMULATION</span>
    <span class="company-name">DAO Farm</span>
    <div class="logo-button">
      <img
        src="../../../assets/LOGO.png"
        class="logo-button-title"
        alt="logo"
      />
      <div class="add-circle-container">
        <img
          src="../../../assets/add-circle.png"
          class="add-icon"
          alt="add_logo"
        />
      </div>
    </div>
  </div>
  <mat-divider class="header-divider"></mat-divider>
  <div class="simulation-preview-header-actions">
    <button mat-button class="download-button">
      <mat-icon>file_download</mat-icon>&nbsp;
      Download
    </button>
    <button mat-button class="print-button">
      <mat-icon>print</mat-icon>&nbsp;
      Print
    </button>
    <button mat-button class="share-button">
      <mat-icon>share</mat-icon>&nbsp;
      Share to show result
    </button>
  </div>
</div>

<div class="simulation-review-dialog-container">
  <div class="first-chart-card">
    <div class="card-header">
      <div class="interval-block">
        <button
          mat-button
          class="time-button"
          [ngClass]="{'active': firstIntervalValue === gridDataIntervalEnum.HOURLY}"
          (click)="changeDataByInterval(gridDataIntervalEnum.HOURLY, 'first')"
        >
          Hourly
        </button>
        <button
          mat-button
          class="time-button"
          [ngClass]="{'active': firstIntervalValue === gridDataIntervalEnum.DAILY}"
          (click)="changeDataByInterval(gridDataIntervalEnum.DAILY, 'first')"
        >
          Daily
        </button>
        <button
          mat-button
          class="time-button"
          [ngClass]="{'active': firstIntervalValue === gridDataIntervalEnum.MONTHLY}"
          (click)="changeDataByInterval(gridDataIntervalEnum.MONTHLY, 'first')"
        >
          Monthly
        </button>
      </div>

      <div class="legend-container">
        <div class="legend-item">
          <mat-icon class="total-community-icon">circle</mat-icon>
          Total community savings
        </div>
        <div class="legend-item">
          <mat-icon class="stress-condition-icon">circle</mat-icon>
          Stress condition
        </div>
        <div class="legend-item">
          <mat-icon class="average-reliability-icon">circle</mat-icon>
          Average reliability rate
        </div>
      </div>
    </div>
    <div class="scale-titles">
      <span>$</span>
      <span>$/kWh</span>
    </div>
    <div class="first-chart-container">
      <canvas
        baseChart
        [datasets]="firstChartData"
        [labels]="firstChartLabels"
        [options]="firstChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
      >
      </canvas>
    </div>
  </div>

  <div class="second-chart-card">
    <div class="card-header">
      <div class="interval-block">
        <button
          mat-button
          class="time-button"
          [ngClass]="{'active': secondIntervalValue === gridDataIntervalEnum.HOURLY}"
          (click)="changeDataByInterval(gridDataIntervalEnum.HOURLY, 'second')"
        >
          Hourly
        </button>
        <button
          mat-button
          class="time-button"
          [ngClass]="{'active': secondIntervalValue === gridDataIntervalEnum.DAILY}"
          (click)="changeDataByInterval(gridDataIntervalEnum.DAILY, 'second')"
        >
          Daily
        </button>
        <button
          mat-button
          class="time-button"
          [ngClass]="{'active': secondIntervalValue === gridDataIntervalEnum.MONTHLY}"
          (click)="changeDataByInterval(gridDataIntervalEnum.MONTHLY, 'second')"
        >
          Monthly
        </button>
      </div>

      <div class="legend-container">
        <div class="legend-item">
          <mat-icon class="demand-icon">circle</mat-icon>
          Demand curve
        </div>
        <div class="legend-item">
          <mat-icon class="solar-yield-icon">circle</mat-icon>
          Solar yield curve
        </div>
      </div>
    </div>
    <div class="second-chart-container">
      <canvas
        baseChart
        [datasets]="secondChartData"
        [labels]="secondChartLabels"
        [options]="secondChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
      >
      </canvas>
    </div>
  </div>
</div>

<div class="sign-container">
  <span class="sign-text">
    Empowered by
  </span>
  <img
    src="../../../assets/h-fullcolor-1.png"
    class="logo"
    alt="logo"
  />
</div>
