import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerHeaderComponent } from "../container-header/container-header.component";
import { MatIconModule } from "@angular/material/icon";



@NgModule({
  declarations: [
    ContainerHeaderComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [
    ContainerHeaderComponent,
  ]
})
export class SharedModule { }
