<div class="custom-consumption-container">
  <div class="custom-consumption-header" *ngIf="data.field === 'industrialCustom'">
    Industrial consumption (kWh)
  </div>
  <div class="custom-consumption-header" *ngIf="data.field === 'commercialHighDemand'">
    Large commercial consumption (kWh)
  </div>
  <div class="custom-consumption-header" *ngIf="data.field === 'anchorCustom'">
    Anchor consumption (kWh)
  </div>
  <div class="fields-row">
    <mat-form-field appearance="outline" class="name-field">
      <div class="label-field">Name</div>
      <input
        matInput
        [formControl]="nameControl"
      >
    </mat-form-field>
    <div class="amount-field">
      <div class="amount-field-title">Amount</div>
      <mat-form-field appearance="outline" class="number-field">
        <input
          matInput
          [formControl]="amountControl"
        >
      </mat-form-field>
      <div class="change-input-buttons">
        <div class="up-button" (click)="incInput()">
          <mat-icon class="up-icon">expand_less</mat-icon>
        </div>
        <div class="down-button" (click)="decInput()">
          <mat-icon class="down-icon">expand_more</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="block-row">
    <div class="block-title">
      Consumption (kWh)<br>
      <span>Select timeframe then drag bars up & down to increase/decrease consumption</span>
    </div>
    <div class="interval-block">
      <button
        mat-button
        class="time-button"
        [ngClass]="{'active': intervalSliderValue === 0}"
        (click)="changeDataByInterval(0)"
      >
        Hourly
      </button>
      <button
        mat-button
        class="time-button"
        [ngClass]="{'active': intervalSliderValue === 50}"
        (click)="changeDataByInterval(50)"
      >
        Daily
      </button>
      <button
        mat-button
        class="time-button"
        [ngClass]="{'active': intervalSliderValue === 100}"
        (click)="changeDataByInterval(100)"
      >
        Monthly
      </button>
    </div>
  </div>
  <div class="barchart-container">
    <canvas
      class="modelingChart"
      baseChart
      width="500"
      height="300"
      [datasets]="consumptionChartData"
      [labels]="consumptionChartLabels"
      [options]="consumptionChartOptions"
      [legend]="true"
      [chartType]="consumptionChartType"
    >
    </canvas>
  </div>
  <button mat-button class="legend-block" *ngIf="data.field === 'industrialCustom'">
    <mat-icon class="residential-icon">circle</mat-icon>
    Industrial
  </button>
  <button mat-button class="legend-block" *ngIf="data.field === 'commercialHighDemand'">
    <mat-icon class="residential-icon">circle</mat-icon>
    Commercial
  </button>
  <button mat-button class="legend-block" *ngIf="data.field === 'anchorCustom'">
    <mat-icon class="residential-icon">circle</mat-icon>
    Anchor
  </button>

  <div class="actions-container">
    <button
      mat-button
      class="cancel-button"
      (click)="closeDialog()"
    >
      Cancel
    </button>
    <button
      mat-button
      class="save-button"
      (click)="save()"
    >
      Save
    </button>
  </div>
</div>
