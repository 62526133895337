<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Enter the value</mat-label>
    <input matInput [formControl]="valueControl" *ngIf="!data.isStringType">
    <textarea matInput [formControl]="valueControl" *ngIf="data.isStringType"></textarea>
    <mat-error *ngIf="valueControl.hasError('min')">
      Value must be equal or greater 0.
    </mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions class="dialog-footer">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onConfirm()">Save</button>
</div>
