import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { MatSliderChange } from "@angular/material/slider";

@Component({
  selector: 'app-logo-uploader-dialog',
  templateUrl: './logo-uploader-dialog.component.html',
  styleUrls: ['./logo-uploader-dialog.component.scss']
})
export class LogoUploaderDialogComponent implements OnInit {
  croppedImage = '';
  imageChangedEvent: any = '';
  showCropper = false;
  scale = 1;
  transform: ImageTransform = {};

  constructor(
    private dialogRef: MatDialogRef<LogoUploaderDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showCropper = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  saveLogo(): void {
    this.dialogRef.close({ image: this.croppedImage });
  }

  zoomIn(): void {
    this.scale += .1;
    this.transform = { scale: this.scale };
  }

  zoomOut(): void {
    this.scale -= .1;
    this.transform = { scale: this.scale };
  }

  onChangeScale(event: MatSliderChange): void {
    this.transform = { scale: event.value};
    console.log(event);
  }

}
