import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private readonly JWT_TOKEN_KEY = 'jwt_token';

  constructor() { }

  storeJwtToken(token: string): void {
    localStorage.setItem(this.JWT_TOKEN_KEY, token);
  }

  getJwtToken(): string | null {
    return localStorage.getItem(this.JWT_TOKEN_KEY);
  }

  removeToken(): void {
    localStorage.removeItem(this.JWT_TOKEN_KEY);
  }

  tokenExpired() {
    const token = this.getJwtToken();
    if (token) {
      const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
      return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
    return true;
  }
}
