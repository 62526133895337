// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://dev-sim-server-i727c4pneq-ue.a.run.app/v1',
  firebase: {
    apiKey: "AIzaSyCutmk5EJVpI5gTC0gnEcSrOG9B6EpjoyM",
    authDomain: "dexsim.firebaseapp.com",
    projectId: "dexsim",
    storageBucket: "dexsim.appspot.com",
    messagingSenderId: "890642769819",
    appId: "1:890642769819:web:6f28d2cea7a32a2b37a33e",
    measurementId: "G-7R2FFK9VZR"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
