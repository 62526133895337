import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { GridDataIntervalEnum } from "../../grid-data-dashboard/grid-data.enum";
import { ChartDataSets, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import { chartsData } from "../../progress/simulation-review/data";

@Component({
  selector: 'app-simulation-review-dialog',
  templateUrl: './simulation-review-dialog.component.html',
  styleUrls: ['./simulation-review-dialog.component.scss']
})
export class SimulationReviewDialogComponent implements OnInit {
  firstIntervalValue = GridDataIntervalEnum.DAILY;
  secondIntervalValue = GridDataIntervalEnum.DAILY;
  gridDataIntervalEnum = GridDataIntervalEnum;
  firstChartData: ChartDataSets[] = [];
  firstChartLabels: Label[] = [];
  firstChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          gridLines: {
            color: '#CBCBCB',
          },
          position: 'left',
          ticks: {
            stepSize : 100,
            max: 400,
            min: 0,
            beginAtZero: true,
          },
        },
        {
          id: 'y-axis-2',
          gridLines: {
            color: '#CBCBCB',
          },
          position: 'right',
          ticks: {
            stepSize : 0.02,
            max: 0.08,
            min: 0,
            beginAtZero: true,
          },
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        }
      ]
    },
  };

  secondChartData: ChartDataSets[] = [];
  secondChartLabels: Label[] = [];
  secondChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: '#CBCBCB',
          },
          ticks: {
            stepSize : 0.2,
            max: 1.2,
            min: 0,
            beginAtZero: true,
          },
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        }
      ]
    },
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'rgb(254, 165, 99)',
      backgroundColor: 'rgba(254, 165, 99, 0.1)',
    },
    {
      borderColor: 'rgb(73, 101, 185)',
      backgroundColor: 'rgba(73, 101, 185, 0.1)',
    },
    {
      borderColor: 'rgb(245, 45, 86)',
      backgroundColor: 'rgba(245, 45, 86, 0.1)',
    },
  ];
  lineChartLegend = false;
  lineChartType: ChartType = 'line';

  constructor(
    private dialogRef: MatDialogRef<SimulationReviewDialogComponent>
  ) {
  }

  ngOnInit(): void {
    this.firstChartData = [ ...chartsData.first[GridDataIntervalEnum.DAILY].data ];
    this.firstChartLabels = [ ...chartsData.first[GridDataIntervalEnum.DAILY].labels ];
    this.secondChartData = [ ...chartsData.second[GridDataIntervalEnum.DAILY].data ];
    this.secondChartLabels = [ ...chartsData.second[GridDataIntervalEnum.DAILY].labels ];
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  changeDataByInterval(interval: number, chart: 'first' | 'second'): void {
    this[chart + 'IntervalValue'] = interval;
    this[chart + 'ChartData'] = [ ...chartsData[chart][this[chart + 'IntervalValue']].data ];
    this[chart + 'ChartLabels'] = [ ...chartsData[chart][this[chart + 'IntervalValue']].labels ];
  }

}
