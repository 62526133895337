import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RunProgramComponent } from "./run-program/run-program.component";
import { NotAuthGuard } from "./guards/not-auth.guard";
import { SimulationsComponent } from "./simulations/simulations.component";
import { SimulationStatusComponent } from "./simulation-status/simulation-status.component";
import { SimulationRunningComponent } from "./simulation-running/simulation-running.component";
import { NotMobileGuard } from "./guards/not-mobile.guard";
import { SharedSimulationComponent } from "./shared-simulation/shared-simulation.component";
import { AccountSettingsComponent } from "./account-settings/account-settings.component";

const routes: Routes = [
  {
    path: '', redirectTo: 'project-progress',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: 'run-program',
        canActivate: [NotMobileGuard, NotAuthGuard],
        component: RunProgramComponent,
      },
      {
        path: 'simulations',
        canActivate: [NotMobileGuard, NotAuthGuard],
        component: SimulationsComponent,
      },
      {
        path: 'simulation-status',
        canActivate: [NotMobileGuard, NotAuthGuard],
        component: SimulationStatusComponent,
      },
      {
        path: 'simulation-running',
        canActivate: [NotMobileGuard, NotAuthGuard],
        component: SimulationRunningComponent,
      },
      {
        path: 'shared/simulations/:token',
        component: SharedSimulationComponent,
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent,
      },
    ]
  },
  {
    path: 'auth',
    canActivate: [NotAuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'project-progress',
    canActivate: [NotMobileGuard, NotAuthGuard],
    loadChildren: () => import('./progress/project-progress.module').then(m => m.ProjectProgressModule),
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
