<div class="status-container">
  <mat-card class="status-card">
    <h1 class="status-title">{{getTitle()}} completed</h1>
    <p class="status-paragraph">
      Congratulation on this important step.<br>
      Let's keep building...
    </p>
    <button mat-flat-button class="view-button" (click)="navigateTo()">
      See my {{getTitle()}}
    </button>
  </mat-card>
</div>
