import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-edit-assumptions-dialog',
  templateUrl: './edit-assumptions-dialog.component.html',
  styleUrls: ['./edit-assumptions-dialog.component.scss']
})
export class EditAssumptionsDialogComponent implements OnInit {
  item: string;
  dataItem: any;
  assumptionsForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { item: string, dataItem: any },
    public dialogRef: MatDialogRef<EditAssumptionsDialogComponent>,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.item = this.data.item;
    this.dataItem = { ...this.data.dataItem };
    this.initForm();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  initForm(): void {
    switch (this.item) {
      case 'systemCapexAssumptions': {
        this.assumptionsForm = this.formBuilder.group({
          solarPvEquipment: [this.dataItem.solarPvEquipment],
          solarDegradation: [this.dataItem.solarDegradation],
          storageSolar: [this.dataItem.storageSolar],
          batteryStorageSystem: [this.dataItem.batteryStorageSystem],
          chpCogenEngineLeaseCost: [this.dataItem.chpCogenEngineLeaseCost],
          fuelCost: [this.dataItem.fuelCost],
          averageLongevity: [this.dataItem.averageLongevity],
          averageEquipmentLeaseTerm: [this.dataItem.averageEquipmentLeaseTerm],
          backupToPrimaryRatio: [this.dataItem.backupToPrimaryRatio],
        });
        break;
      }
      case 'epcCapexAssumptions': {
        this.assumptionsForm = this.formBuilder.group({
          sitePreparation: [this.dataItem.sitePreparation],
          technicalConsultants: [this.dataItem.technicalConsultants],
          reactivePowerInverters: [this.dataItem.reactivePowerInverters],
          smartMeters: [this.dataItem.smartMeters],
          transferSwitches: [this.dataItem.transferSwitches],
          remoteBreakers: [this.dataItem.remoteBreakers],
          dexgridServices: [this.dataItem.dexgridServices],
          microgridControlCenter: [this.dataItem.microgridControlCenter],
          standaloneDistributionGrid: [this.dataItem.standaloneDistributionGrid],
          others: [this.dataItem.others],
        });
        break;
      }
      case 'opexAssumptions': {
        this.assumptionsForm = this.formBuilder.group({
          standaloneGridLease: [this.dataItem.standaloneGridLease],
          dexgridPostContractedPeriod: [this.dataItem.dexgridPostContractedPeriod],
          landLeasing: [this.dataItem.landLeasing],
          fuelGenerationCapacity: [this.dataItem.fuelGenerationCapacity],
          managementStaff: [this.dataItem.managementStaff],
          legalAndAccounting: [this.dataItem.legalAndAccounting],
          operator: [this.dataItem.operator],
          security: [this.dataItem.security],
          insurance: [this.dataItem.insurance],
          others: [this.dataItem.others],
        });
        break;
      }
      case 'otherItemsAssumptions': {
        this.assumptionsForm = this.formBuilder.group({
          inflation: [this.dataItem.inflation],
          discountRate: [this.dataItem.discountRate],
          consumerRate: [this.dataItem.consumerRate],
          investmentTaxCredit: [this.dataItem.investmentTaxCredit],
          renewableEnergyCertificatesRecs: [this.dataItem.renewableEnergyCertificatesRecs],
        });
        break;
      }
    }
  }

  save(): void {
    this.dataItem = this.assumptionsForm.value;
    this.dialogRef.close({ dataItem: this.dataItem });
  }

  decValue(field: string): void {
    this.assumptionsForm.controls[field].patchValue(this.assumptionsForm.value[field] - 1);
  }

  incValue(field: string): void {
    this.assumptionsForm.controls[field].patchValue(this.assumptionsForm.value[field] + 1);
  }

}
