<div class="dashboard-wrapper">
  <div class="dashboard-header">
    <h1>Operation simulation</h1>
    <button mat-button class="operation-sim-dashboard-button" routerLink="/project-progress">Dashboard</button>
  </div>
  <div class="dashboard-cards-wrapper">
    <div class="left-container">
      <div class="top-container">
        <button mat-button class="update-button">Update Parameters</button>
        <mat-select [(value)]="selected" class="consumption-select">
          <mat-option value="hourly">
            Hourly
          </mat-option>
          <mat-option value="daily">
            Daily
          </mat-option>
          <mat-option value="monthly">
            Monthly
          </mat-option>
        </mat-select>
        <div class="progress-container">
          <mat-progress-spinner
            class="example-margin"
            [mode]="'determinate'"
            [value]="100"
          >
          </mat-progress-spinner>
          <div class="progress-text-container">
            <p class="progress-percent">100%</p>
            <p class="progress-title">Uptime</p>
          </div>
        </div>
      </div>
      <mat-card class="trend-card">
        <canvas baseChart width="400" height="200" *ngIf="selected === 'hourly'"
          [datasets]="hourlyChartData"
          [labels]="hourlyChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
        >
        </canvas>
        <canvas baseChart width="400" height="200" *ngIf="selected === 'daily'"
          [datasets]="dailyChartData"
          [labels]="dailyChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
        >
        </canvas>
        <canvas baseChart width="400" height="200" *ngIf="selected === 'monthly'"
          [datasets]="monthlyChartData"
          [labels]="monthlyChartLabels"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
        >
        </canvas>
      </mat-card>
    </div>
    <div class="right-container">
      <mat-card class="key-numbers-card">
        <div class="key-numbers-container">
          <p class="card-title">Demand Response (DR) Program</p>
          <mat-divider class="header-divider"></mat-divider>
          <p class="subtitle">Supporting the community microgrid</p>
          <mat-divider class="key-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Microgrid stress
              </p>
              <p class="title-prompt-paragraph">
                *How much DR reduces overall stress
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].microgridStress}}
            </p>
          </div>
          <mat-divider class="value-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Emergency backup generation
              </p>
              <p class="title-prompt-paragraph">
                *How much DR reduces need for backup generation
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].emergencyBackupGeneration}}
            </p>
          </div>
          <mat-divider class="value-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Anchor economic performance
              </p>
              <p class="title-prompt-paragraph">
                *How much DR increases anchor's average annual revenue
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].anchorEconomicPerformance}}
            </p>
          </div>
          <mat-divider class="value-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Average consumer cost
              </p>
              <p class="title-prompt-paragraph">
                *How much DR reduces consumer $ cost on average
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].averageConsumerCost}}
            </p>
          </div>
          <mat-divider class="value-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Average consumer savings
              </p>
              <p class="title-prompt-paragraph">
                *How much DR increases average consumer savings in %
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].averageConsumerSavings}}
            </p>
          </div>
          <mat-divider class="value-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Average consumer EC duration
              </p>
              <p class="title-prompt-paragraph">
                *How much DR increases average Energy Credits duration
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].averageConsumerEcDuration}}
            </p>
          </div>
          <mat-divider class="block-divider"></mat-divider>
          <p class="subtitle">Supporting the responsible consumer</p>
          <mat-divider class="key-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Individual consumption over period
              </p>
              <p class="title-prompt-paragraph">
                *% of simulated consumption over/under projected level
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].individualConsumptionOverPeriod}}
            </p>
          </div>
          <mat-divider class="value-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Reliability rate over period
              </p>
              <p class="title-prompt-paragraph">
                *Additional individual charge supporting the microgrid reliability
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].reliabilityRateOverPeriod}}
            </p>
          </div>
          <mat-divider class="value-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Individual consumer EC duration
              </p>
              <p class="title-prompt-paragraph">
                *How much simulated individual consumption affects EC duration
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].individualConsumerEcDuration}}
            </p>
          </div>
          <mat-divider class="value-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Individual consumption cost over period
              </p>
              <p class="title-prompt-paragraph">
                *How much simulated individual consumption affects costs
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].individualConsumptionCostOverPeriod}}
            </p>
          </div>
          <mat-divider class="value-divider"></mat-divider>
          <div class="key-number-container">
            <div class="key-number-title">
              <p class="key-number-subtitle">
                Savings from DR program
              </p>
              <p class="title-prompt-paragraph">
                *How much DR program savings to individual consumer
              </p>
            </div>
            <p class="key-number-value">
              {{this.operationSimDashboard.demandResponseProgram[selected].savingsFromDrProgram}}
            </p>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="navigation-container">
    <button mat-button class="back-button">
      <mat-icon class="cancel-icon">cancel</mat-icon>
      Back
    </button>
    <button mat-button class="preview-button">
      Preview
    </button>
    <button mat-button class="share-button" (click)="openShareDialog()">
      Share
    </button>
  </div>
</div>
