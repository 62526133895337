<div class="projects-dialog-container">
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
  <div class="projects-dialog-title">
    Projects manager<br>
    <span class="projects-dialog-subtitle">
      Select which project to model or click New project to model a new project
    </span>
  </div>
    <mat-form-field appearance="outline" class="select-project-field">
      <mat-select
        [(value)]="selectedProject"
        panelClass="projects-dialog-select"
      >
        <mat-option *ngFor="let project of projectsList" value="{{project.id}}">
          {{ project.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  <div class="projects-dialog-actions">
    <button mat-button class="new-project-button" (click)="setProject(true)">New project</button>
    <button mat-button class="submit-button" (click)="setProject()">Submit</button>
  </div>
</div>
