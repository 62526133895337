<div class="header-container">
  <div class="header-title">
    {{title ? title[0] : ''}}
    <div class="header-subtitle" *ngIf="title && title[1]">
      <mat-icon>chevron_right</mat-icon>
      <span>
        {{title[1]}}
      </span>
    </div>
  </div>
  <div class="page-buttons">
    <div class="report-button">
      <img
        src="../../assets/report-icon.png"
        class="report-icon"
        alt="report_icon"
      />
      <span>Market Stats</span>
    </div>
    <div class="invite-button" (click)="openInviteDialog()">
      <mat-icon class="add-icon">add</mat-icon>
      <span>Invite Someone to Join Project</span>
    </div>
  </div>
</div>
