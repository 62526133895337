import { Component, OnInit } from '@angular/core';
import { HeaderService } from "../services/header.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { LogoUploaderDialogComponent } from "../dialogs/logo-uploader-dialog/logo-uploader-dialog.component";
import { Profile } from "../models/profile";
import { ProfileService } from "../services/profile.service";
import { combineLatest } from "rxjs";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { LocalizationService } from "../services/localization.service";

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  accountForm: FormGroup;
  companyForm: FormGroup;
  accountPreferencesForm: FormGroup;
  receiveAlertsExpanded = false;
  companyLogo: Blob;
  profile: Profile;

  constructor(
    private headerService: HeaderService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private localizationService: LocalizationService,
  ) {
  }

  ngOnInit(): void {
    this.headerService.headerTitle = 'Settings';

    this.profileService.getAuthenticatedUser().subscribe((profile: Profile) => {
      this.profile = profile;

      this.accountForm = this.formBuilder.group({
        name: [this.profile.contact.name || ''],
        email: [this.profile.contact.email || ''],
        address: [this.profile.contact.address || ''],
        phone: [this.profile.contact.phone || ''],
        website: [this.profile.contact.website || '']
      });
      this.companyForm = this.formBuilder.group({
        name: [this.profile.company.contact.name || ''],
        email: [this.profile.company.contact.email || ''],
        address: [this.profile.company.contact.address || ''],
        phone: [this.profile.company.contact.phone || ''],
        website: [this.profile.company.contact.website || ''],
        bio: [this.profile.company.bio || ''],
      });
      this.accountPreferencesForm = this.formBuilder.group({
        isNotifyPaused: [this.profile.isNotifyPaused || false],
        isNotifyEmail: [this.profile.isNotifyEmail || false],
        isAlertUnknownLogin: [this.profile.isAlertUnknownLogin || false],
      });
    });
  }

  openLogoUploaderDialog(): void {
    const dialogRef = this.dialog.open(LogoUploaderDialogComponent, {
      panelClass: 'logo-uploader-dialog',
      width: '535px',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult?.image) {
        this.companyLogo = dialogResult.image;
      }
    });
  }

  saveProfileChanges(): void {
    const profileData = {
      contact: this.accountForm.value,
    };
    const companyData = {
      ...this.companyForm.value,
      logo: this.companyLogo,
    };
    combineLatest([
      this.profileService.saveProfileChanges(profileData),
      this.profileService.saveCompanyChanges(companyData)
    ]).subscribe();
  }

  saveLanguage(event: MatSlideToggleChange): void {
    this.localizationService.saveLanguageSettings(event.checked ? 'en' : 'es');
    this.profileService.saveProfileChanges({ language: event.checked ? 'ENGLISH' : 'SPANISH'}).subscribe();
  }

  savePreferences(): void {
    this.profileService.saveProfileChanges(this.accountPreferencesForm.value).subscribe();
  }

}
