import { Profile } from "../models/profile";

export const profileMock: Profile = {
  "contact": {
    "name": "Energy Inc.",
    "address": "123 Main St.",
    "email": "company@example.com",
    "phone": "9876543210",
    "website": "www.example.com"
  },
  "company": {
    "id": "a35cb7f9-296c-4dc6-be64-e2ec403d4f50",
    "contact": {
      "name": "Energy Inc.",
      "address": "123 Main St.",
      "email": "company@example.com",
      "phone": "9876543210",
      "website": "www.example.com"
    },
    "bio": "a long bio",
    "logoUrl": "http://us.gcp.com/12345abcdef"
  },
  "language": "ENGLISH",
  "isNotifyPaused": false,
  "isNotifyEmail": true,
  "isAlertUnknownLogin": true
};


