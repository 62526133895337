<div class="dashboard-wrapper">
  <div class="dashboard-header">
    <h1>Grid Data Dashboard</h1>
    <button mat-flat-button class="submit-button" routerLink="/run-program">
      Rerun Simulation
    </button>
  </div>
  <div class="dashboard-cards-wrapper">
    <mat-card class="params-card">
      <mat-card-header>
        <mat-card-title>
          <p class="card-title">Simulation Parameters</p>
        </mat-card-title>
      </mat-card-header>
      <label>AVERAGE DAILY CONSUMPTION</label>
      <p>11,240 kWh</p>
      <label>PEAK CONSUMPTION LEVEL</label>
      <p>900 kW</p>
      <label>TIME PERIOD</label>
      <p>{{getIntervalPeriodText()}}</p>
      <label>TOTAL CONSUMERS</label>
      <p>25</p>
      <label>REGION</label>
      <p>Caguas, Puerto Rico</p>
    </mat-card>

    <div class="trend-container">
      <mat-card class="trend-card">
        <mat-card-header>
          <mat-card-title>
            <p class="card-title">Consumption in kWh</p>
          </mat-card-title>
          <div class="interval-container">
            <p>{{getIntervalTitle()}}</p>
            <mat-slider
              [step]="50"
              [(ngModel)]="intervalSliderValue"
              (change)="changeDataByInterval()"
            >
            </mat-slider>
          </div>
        </mat-card-header>
        <canvas class="modelingChart"
          baseChart width="500" height="300"
          [datasets]="consumptionChartData"
          [labels]="consumptionChartLabels"
          [options]="consumptionChartOptions"
          [legend]="true"
          [chartType]="consumptionChartType"
        >
        </canvas>
      </mat-card>
<!--      <mat-select [(value)]="selected" class="consumption-select">-->
<!--        <mat-option value="Hourly">-->
<!--          Hourly-->
<!--        </mat-option>-->
<!--        <mat-option value="Daily">-->
<!--          Daily-->
<!--        </mat-option>-->
<!--        <mat-option value="Monthly">-->
<!--          Monthly-->
<!--        </mat-option>-->
<!--      </mat-select>-->
    </div>

    <mat-card class="breakdown-card">
      <mat-card-header>
        <mat-card-title>
          <p class="card-title">Breakdown by Consumer</p>
        </mat-card-title>
      </mat-card-header>
      <canvas baseChart width="200" height="200" class="breakdown-chart"
        [data]="breakdownChartData"
        [labels]="breakdownChartLabels"
        [chartType]="breakdownChartType"
        [legend]="false"
        [colors]="breakdownChartColors"
        [options]="breakdownChartOptions"
      >
      </canvas>
      <div class="legend-container">
        <div class="legend-row">
          <mat-icon class="residential-icon">circle</mat-icon>
          <div class="legend-row-name">Residential</div>
          <label class="legend-row-value">300.75 kWh</label>
        </div>
        <mat-divider></mat-divider>
        <div class="legend-row">
          <mat-icon class="commercial-icon">circle</mat-icon>
          <div class="legend-row-name">Commercial</div>
          <label class="legend-row-value">834.11 kWh</label>
        </div>
        <mat-divider></mat-divider>
        <div class="legend-row">
          <mat-icon class="manufactures-icon">circle</mat-icon>
          <div class="legend-row-name">Manufactures</div>
          <label class="legend-row-value">507.38 kWh</label>
        </div>
        <mat-divider></mat-divider>
        <div class="legend-row">
          <mat-icon class="other-icon">circle</mat-icon>
          <div class="legend-row-name">Other</div>
          <label class="legend-row-value">146.24 kWh</label>
        </div>
      </div>
    </mat-card>
  </div>
</div>
