<agm-map
  [latitude]="lat"
  [longitude]="lng"
  [zoom]="zoom"
  [mapTypeId]="'satellite'"
  (mapClick)="mapClicked($event)"
>

  <agm-marker
    *ngFor="let marker of markers"
    [latitude]="marker.lat"
    [longitude]="marker.lng"
    [label]="marker.label"
  >
  </agm-marker>

</agm-map>
<div class="dialog-buttons-wrapper">
  <button mat-button class="cancel-dialog-button" (click)="onCancel()">Close</button>
</div>
