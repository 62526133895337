import { environment } from "../environments/environment";

export const API_URL = environment.api_url;

export enum GridDataIntervalEnum {
  HOURLY = 0,
  DAILY = 50,
  MONTHLY = 100,
}

export const consumptionLabelsMapping = {
  [GridDataIntervalEnum.HOURLY]: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
  [GridDataIntervalEnum.DAILY]: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  [GridDataIntervalEnum.MONTHLY]: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
};

export const consumptionTimePeriodsMapping = {
  [GridDataIntervalEnum.HOURLY]: '24 hours',
  [GridDataIntervalEnum.DAILY]: '7 days',
  [GridDataIntervalEnum.MONTHLY]: '12 months ',
};

export const consumptionChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        stacked: true,
        gridLines: {
          color: '#CBCBCB',
        },
        ticks: {
          // stepSize : 5000,
          // max: 20000,
          min: 0,
        }
      }
    ],
    xAxes: [
      {
        barPercentage: 0.7,
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          callback: (label: string) => label.length === 5 ? label : `${label.substring(0, 3)}`
        }
      }
    ]
  },
  legend: { display: false },
};
