import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomConsumerData } from "../../models/consumers";
import { FormControl } from "@angular/forms";
import { GridDataIntervalEnum } from "../../grid-data-dashboard/grid-data.enum";
import { ChartDataSets, ChartType } from "chart.js";
import { BaseChartDirective, Label } from "ng2-charts";
import {
  consumptionChartOptions,
  consumptionDataMapping,
  consumptionLabelsMapping
} from "../../grid-data-dashboard/data";

@Component({
  selector: 'app-custom-consumption-dialog',
  templateUrl: './custom-consumption-dialog.component.html',
  styleUrls: ['./custom-consumption-dialog.component.scss']
})
export class CustomConsumptionDialogComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  fieldData: CustomConsumerData;
  nameControl: FormControl;
  amountControl: FormControl;
  intervalSliderValue = GridDataIntervalEnum.DAILY;

  consumptionChartData: ChartDataSets[] = [];
  consumptionChartOptions: any = {};
  consumptionChartLabels: Label[] = [];
  consumptionChartType: ChartType = 'bar';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { field: string, fieldData: CustomConsumerData },
    public dialogRef: MatDialogRef<CustomConsumptionDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    this.fieldData = { ...this.data.fieldData };
    this.nameControl = new FormControl(this.fieldData.name || 'Name');
    this.amountControl = new FormControl(this.fieldData.amount || 0);
    this.consumptionChartOptions = {
      ...consumptionChartOptions,
      dragData: true,
      dragX: false,
      dragDataRound: 10
    };
    this.initChartData();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.fieldData.data[this.intervalSliderValue] = [...this.chart?.chart.config.data?.datasets[0].data] as number[];
    this.fieldData.name = this.nameControl.value;
    this.fieldData.amount = this.amountControl.value;
    this.dialogRef.close({ fieldData: this.fieldData });
  }

  incInput(): void {
    this.amountControl.patchValue(this.amountControl.value + 1);
  }

  decInput(): void {
    this.amountControl.patchValue(this.amountControl.value - 1);
  }

  initChartData(): void {
    this.consumptionChartData = [{...consumptionDataMapping[this.intervalSliderValue][0]}] as ChartDataSets[];
    this.consumptionChartData[0].data = [...this.fieldData.data[this.intervalSliderValue]];
    this.consumptionChartData[0].label = 'Peak';
    this.consumptionChartLabels = consumptionLabelsMapping[this.intervalSliderValue];
  }

  changeDataByInterval(interval: number): void {
    this.fieldData.data[this.intervalSliderValue] = this.chart?.chart.config.data?.datasets[0].data as number[];
    this.intervalSliderValue = interval;
    this.initChartData();
  }

}
