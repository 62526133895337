import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { filter, map, takeUntil } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { User } from "../models/user";
import { HeaderService } from "../services/header.service";

export const PAGES_WITHOUT_HEADER = [
  '/auth',
  '/auth/login',
  '/auth/register',
  '/company-info',
]

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showHeader$!: Observable<boolean>;
  user: User;
  headerTitle: string;
  private readonly destroyed$: Subject<void> = new Subject<void>();

  constructor(
    public router: Router,
    private authService: AuthService,
    private headerService: HeaderService,
  ) {
  }

  ngOnInit(): void {
    this.showHeader$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        return !PAGES_WITHOUT_HEADER.some(page => this.router.url.startsWith(page));
      }),
      takeUntil(this.destroyed$),
    );
    this.authService.getCurrentUser$().subscribe(user => {
      this.user = user;
    });
    this.headerService.headerTitle$.subscribe(title => this.headerTitle = title);
  }

  logout(): void {
    this.authService.logout();
  }

  navigateToProfile(): void {
    this.router.navigate(['account-settings']);
  }

}
