import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Simulation } from "../../models/simulation";
import { SimulationService } from "../../services/simulation.service";
import { ProgressService } from "../../progress/progress.service";
import { CurrentsimulationService } from 'src/app/services/currentsimulation.service';

@Component({
  selector: 'app-projects-manager-dialog',
  templateUrl: './projects-manager-dialog.component.html',
  styleUrls: ['./projects-manager-dialog.component.scss']
})
export class ProjectsManagerDialogComponent implements OnInit {
  selectedProject: any;
  projectsList: Simulation[];

  constructor(
    public dialogRef: MatDialogRef<ProjectsManagerDialogComponent>,
    private matDialog: MatDialog,
    private simulationService: SimulationService,
    private progressService: ProgressService,
    private currentSim: CurrentsimulationService
  ) {
  }

  ngOnInit(): void {
    // this.simulationService.getSimulationsList().subscribe(simulations => {
    //   this.projectsList = simulations;
    //   this.selectedProject = this.simulationService.currentSimulation.id;
    // });
    this.currentSim.currentSimulationID.subscribe(simID => {
      this.selectedProject = simID
    })

    this.currentSim.getSimulationsList().subscribe(sims => {
      this.projectsList = sims
    })
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  setProject(newProject = false): void {
    if (newProject) {
      this.progressService.resetProgress();
      // this.simulationService.resetSimulation();
    } else {
      this.simulationService.currentSimulation = this.projectsList.find(project => project.id === this.selectedProject);
    }
    this.dialogRef.close();
  }

}
