import { Injectable } from '@angular/core';
import { TokenService } from "./token.service";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API_URL } from "../constants";
import { tap } from "rxjs/operators";
import { User } from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user$ = new BehaviorSubject<User>({} as User);
  private userEmail: string = '';

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private http: HttpClient,
  ) {
  }

  get authData(): string {
    return this.userEmail;
  }

  isAuthenticated() {
    return !this.tokenService.tokenExpired();
  }

  logout(): void {
    this.tokenService.removeToken();
    this.user$.next({} as User);
    this.router.navigate(['auth']);
  }

  register(email: string): Observable<any> {
    this.userEmail = email;
    return this.http.post(`${API_URL}/auth/register/email`, null);
  }

  verifyRegister(authToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    });
    return this.http.post<any>(`${API_URL}/auth/register/email/verify`, null, { headers }).pipe(
      tap(user => {
        this.user$.next(user);
        this.setLogin(user.authToken);
      })
    );
  }

  resendVerifyRegister(data: { email: string }): Observable<any> {
    if (data?.email) {
      this.userEmail = data.email;
    }
    return this.http.post<any>(`${API_URL}/auth/register/email/verify/resend`, null);
  }

  login(data: { email: string }): Observable<any> {
    this.userEmail = data.email;
    return this.http.post<any>(`${API_URL}/auth/login/email`, null);
  }

  verifyLogin(authToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    });
    return this.http.post<any>(`${API_URL}/auth/login/email/verify`, null, { headers }).pipe(
      tap(user => {
        this.user$.next(user);
        this.setLogin(user.authToken);
      })
    );
  }

  getCurrentUser$(): Observable<User> {
    return this.user$.asObservable();
  }

  private setLogin(token: string): void {
    this.tokenService.storeJwtToken(token);
  }
}
