import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { CapexOpexData } from "../../models/capex-opex-dashboard";
import { ChartDataSets, ChartType } from "chart.js";
import { Color, Label, SingleDataSet } from "ng2-charts";
import { SimRevision, Simulation } from "../../models/simulation";
import { SimulationService } from "../../services/simulation.service";

@Component({
  selector: 'app-financials-review-dialog',
  templateUrl: './financials-review-dialog.component.html',
  styleUrls: ['./financials-review-dialog.component.scss']
})
export class FinancialsReviewDialogComponent implements OnInit {
  currentSimulation: Simulation;
  simRevision: SimRevision;
  capexOpexData: CapexOpexData;

  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'];
  lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: '#CBCBCB',
          },
          ticks: {
            stepSize : 1,
            max: 5,
            min: 0,
            beginAtZero: true,
            callback: this.formatFinance
          },
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        }
      ]
    },
    legend: {
      position: 'top',
      labels: {
        fontSize: 14,
        fontColor: '#ffffff',
        usePointStyle: true
      }
    }
  };
  lineChartColors: Color[] = [
    {
      borderColor: 'rgb(254, 165, 99)',
      backgroundColor: 'rgba(254, 165, 99, 0.1)',
    },
    {
      borderColor: 'rgb(152, 64, 221)',
      backgroundColor: 'rgba(152, 64, 221, 0.1)',
    },
    {
      borderColor: 'rgb(0, 122, 255)',
      backgroundColor: 'rgba(0, 122, 255, 0.1)',
    },
  ];
  lineChartLegend = false;
  lineChartType: ChartType = 'line';
  lineChartPlugins = [];

  generationCapacityChartLabels: Label[] = ['Primary', 'Backup'];
  generationCapacityChartData: SingleDataSet = [];
  generationCapacityChartType: ChartType = 'doughnut';
  generationCapacityChartOptions: any = { cutoutPercentage: 60, rotation: Math.PI };
  generationCapacityChartColors: any = [
    {
      backgroundColor: ['#F08035', '#9840DD'],
      borderColor: ['#F08035', '#9840DD'],
      borderWidth: 1
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<FinancialsReviewDialogComponent>,
    private simulationService: SimulationService,
  ) {
  }

  ngOnInit(): void {
    this.currentSimulation = this.simulationService.currentSimulation;
    this.simulationService.getSimRevision(
      this.currentSimulation.id,
      this.currentSimulation.latestRevision.id
    ).subscribe((revision: SimRevision) => {
      this.simRevision = revision;
      this.initKeyNumbers();
    });
  }

  initKeyNumbers(): void {
    this.capexOpexData = {
      estimatedCashFlow: {
        electricitySales: [0.6, 2, 2.5, 3.5, 4.1],
        costOfSales: [1, 1.2, 1.5, 1.7, 2.1, 2.5],
        netCf: this.simRevision.output.usage.year.data.map(data => data.financials.income.netIncome / 1000000),
      },
      generationCapacity: {
        primaryGeneration: {
          totalKW: this.simRevision.output.energy.capacity.tiers[0].total,
          methods: [
            ...this.simRevision.output.energy.capacity.tiers[0].generation.methods.map(method => {return { name: method.name, kW: method.kW }}),
            ...this.simRevision.output.energy.capacity.tiers[0].storage.methods.map(method => {return { name: method.name, kW: method.kW }}),
          ]
        },
        backupGeneration: {
          totalKW: this.simRevision.output.energy.capacity.tiers[1].total,
          methods: [
            ...this.simRevision.output.energy.capacity.tiers[1].generation.methods.map(method => {return { name: method.name, kW: method.kW }}),
            ...this.simRevision.output.energy.capacity.tiers[1].storage.methods.map(method => {return { name: method.name, kW: method.kW }}),
          ]
        },
      },
      keyNumbers: {
        totalInvestment: this.simRevision.output.meta.financials.totalInvestment,
        irr: this.simRevision.output.meta.financials.irrPct,
        npv: this.simRevision.output.meta.financials.npv,
        itcDiscount: this.simRevision.output.meta.financials.itcDiscountAvail,
        recRevenue: this.simRevision.output.meta.financials.recRevenueAvail,
        totalAnnualLoanRepayment: this.simRevision.output.meta.financials.annualLoanPayment,
        totalAnnualProduction: this.simRevision.output.usage.year.meta.energy.average.produced,
        totalAnnualConsumption: this.simRevision.output.usage.year.meta.energy.average.consumed,
        annualOpex: this.simRevision.output.meta.financials.avgAnnualOpex,
        system: this.simRevision.output.meta.financials.systemPct,
        epc: this.simRevision.output.meta.financials.epcPct,
      }
    };

    this.lineChartData = [
      { data: this.capexOpexData.estimatedCashFlow.electricitySales, label: 'Electricity Sales' },
      { data: this.capexOpexData.estimatedCashFlow.costOfSales, label: 'Cost of Sales' },
      { data: this.capexOpexData.estimatedCashFlow.netCf, label: 'Net CF' },
    ];

    this.generationCapacityChartData = [
      this.capexOpexData.generationCapacity.primaryGeneration.totalKW,
      this.capexOpexData.generationCapacity.backupGeneration.totalKW,
    ];
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  formatFinance(value: unknown): string {
    return value + ' MM';
  }

}
