<div class="factsheet-review-dialog-header">
  <div class="dialog-title">
    PREVIEW MODE<br>
    <span>You are previewing how your guest will see the final results of load demand</span>
  </div>
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
</div>

<div class="factsheet-preview-header">
  <div class="factsheet-preview-header-title">
    <span class="preview-header">Welcome!</span>
    <span class="company-name">DAO Farm</span>
    <div class="logo-button">
      <img
        src="../../../assets/LOGO.png"
        class="logo-button-title"
        alt="logo"
      />
      <div class="add-circle-container">
        <img
          src="../../../assets/add-circle.png"
          class="add-icon"
          alt="add_logo"
        />
      </div>
    </div>
  </div>
  <mat-divider class="header-divider"></mat-divider>
  <div class="factsheet-preview-header-actions">
    <button mat-button class="navigation-button" *ngIf="isFirstPage" (click)="isFirstPage = false">
      Next page&nbsp;
      <mat-icon>chevron_right</mat-icon>
    </button>
    <button mat-button class="navigation-button" *ngIf="!isFirstPage" (click)="isFirstPage = true">
      <mat-icon>chevron_left</mat-icon>&nbsp;
      Previous page
    </button>
    <button mat-button class="download-button">
      <mat-icon>file_download</mat-icon>&nbsp;
      Download
    </button>
    <button mat-button class="print-button">
      <mat-icon>print</mat-icon>&nbsp;
      Print
    </button>
    <button mat-button class="share-button">
      <mat-icon>share</mat-icon>&nbsp;
      Share to show result
    </button>
  </div>
</div>

<div class="factsheet-review-dialog-container">
  <div class="fact-sheet-review-container first-page" *ngIf="isFirstPage">
    <div class="fact-sheet-left-column">
      <div class="company-info">
        <img src="../../../assets/DAO-logo-1.png" alt="dao_logo">
        <div class="website-container">
          Website<br>
          <span>www.dao.com</span>
        </div>
        <div class="email-container">
          Email<br>
          <span>John@dao.com</span>
        </div>
      </div>
      <div class="key-numbers">
        <div class="block-row">
          <div class="block-title">
            Key numbers
            <span>Estimated</span>
          </div>
        </div>
        <mat-divider class="block-divider"></mat-divider>
        <div class="key-numbers-container">
          <div class="key-number-container">
            <p class="key-number-title text-bold">Total Investment</p>
            <p class="key-number-value text-bold">${{capexOpexData.keyNumbers.totalInvestment}} MM</p>
          </div>
          <div class="key-number-container">
            <p class="key-number-title text-18">IRR</p>
            <p class="key-number-value text-18">{{capexOpexData.keyNumbers.irr}}%</p>
          </div>
          <div class="key-number-container">
            <p class="key-number-title text-18">NPV</p>
            <p class="key-number-value text-18">${{capexOpexData.keyNumbers.npv}} MM</p>
          </div>
          <mat-divider class="block-divider"></mat-divider>
          <div class="key-number-container">
            <p class="key-number-title">System (% of total CAPEX)</p>
            <p class="key-number-value">{{capexOpexData.keyNumbers.system}}%</p>
          </div>
          <div class="key-number-container">
            <p class="key-number-title">EPC (% of total CAPEX)</p>
            <p class="key-number-value">{{capexOpexData.keyNumbers.epc}}%</p>
          </div>
          <div class="key-number-container">
            <p class="key-number-title">Annual OPEX</p>
            <p class="key-number-value">${{capexOpexData.keyNumbers.annualOpex | number}}</p>
          </div>
          <mat-divider class="block-divider"></mat-divider>
          <div class="key-number-container">
            <p class="key-number-title">Total annual production</p>
            <p class="key-number-value">{{capexOpexData.keyNumbers.totalAnnualProduction | number}} kWh</p>
          </div>
          <div class="key-number-container">
            <p class="key-number-title">Total annual consumption</p>
            <p class="key-number-value">{{capexOpexData.keyNumbers.totalAnnualConsumption | number}} kWh</p>
          </div>
          <mat-divider class="block-divider"></mat-divider>
          <div class="key-number-container">
            <p class="key-number-title">ITC discount</p>
            <p class="key-number-value">${{capexOpexData.keyNumbers.itcDiscount | number}}</p>
          </div>
          <div class="key-number-container">
            <p class="key-number-title">REC revenue</p>
            <p class="key-number-value">${{capexOpexData.keyNumbers.recRevenue | number}}</p>
          </div>
          <div class="key-number-container">
            <p class="key-number-title">Total annual loan repayment</p>
            <p class="key-number-value">${{capexOpexData.keyNumbers.totalAnnualLoanRepayment | number}}</p>
          </div>
        </div>
      </div>
    </div>
    <mat-divider class="v-line" [vertical]="true"></mat-divider>
    <div class="fact-sheet-right-column">
      <div class="block-row">
        <div class="block-title">
          Project information
        </div>
      </div>
      <mat-divider class="block-divider"></mat-divider>
      <div class="project-info-title">
        Problem to solve
      </div>
      <div class="project-info-text">
        Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense
      </div>
      <div class="project-info-title">
        Our solution
      </div>
      <div class="project-info-text">
        Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense
      </div>
      <div class="project-info-title">
        How we are doing it
      </div>
      <div class="project-info-text">
        Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense
      </div>
      <div class="block-row">
        <div class="block-title">
          Generation capacity
        </div>
      </div>
      <div class="generation-capacity-container">
        <div class="chart-container">
          <canvas
            #chartCanvas
            baseChart width="160"
            height="160"
            class="breakdown-chart"
            [data]="generationCapacityChartData"
            [labels]="generationCapacityChartLabels"
            [chartType]="generationCapacityChartType"
            [legend]="false"
            [colors]="generationCapacityChartColors"
            [options]="generationCapacityChartOptions"
          >
          </canvas>
        </div>
        <div class="legend-container">
          <div class="legend-row">
            <mat-icon class="primary-generation-icon">circle</mat-icon>
            <div class="legend-row-name">Primary generation</div>
            <div class="legend-row-value">{{this.capexOpexData.generationCapacity.primaryGeneration}} kW</div>
          </div>
          <div class="sub-legend-row">
            <div class="legend-row-name">Solar</div>
            <div class="legend-row-value">{{this.capexOpexData.generationCapacity.primarySolar}} kW</div>
          </div>
          <div class="sub-legend-row">
            <div class="legend-row-name">Battery storage</div>
            <div class="legend-row-value">{{this.capexOpexData.generationCapacity.primaryBatteries}} kWh</div>
          </div>
          <div class="sub-legend-row">
            <div class="legend-row-name">Propane engine</div>
            <div class="legend-row-value">{{this.capexOpexData.generationCapacity.primaryEngine}} kW</div>
          </div>
          <mat-divider class="legend-divider"></mat-divider>
          <div class="legend-row">
            <mat-icon class="backup-generation-icon">circle</mat-icon>
            <div class="legend-row-name">Backup generation</div>
            <div class="legend-row-value">{{this.capexOpexData.generationCapacity.backupGeneration}} kW</div>
          </div>
          <div class="sub-legend-row">
            <div class="legend-row-name">Propane engine</div>
            <div class="legend-row-value">{{this.capexOpexData.generationCapacity.backupEngine}} kW</div>
          </div>
        </div>
        <div class="info-container">
          <div class="info-icon-container">
            <mat-icon class="info-icon">info</mat-icon>
            <div class="info-tooltip">
              Balance (in %) between primary and backup generation capacity under normal operations
            </div>
          </div>
          <div class="primary-value">(80%)</div>
          <div class="info-icon-container backup">
            <mat-icon class="info-icon">info</mat-icon>
            <div class="info-tooltip">
              Balance (in %) between primary and backup generation capacity under normal operations
            </div>
          </div>
          <div class="backup-value">(20%)</div>
        </div>
      </div>
    </div>
  </div>

  <div class="fact-sheet-review-container second-page" *ngIf="!isFirstPage">
    <div class="fact-sheet-left-column">
      <div class="block-row">
        <div class="block-title">
          Contact us:
        </div>
      </div>
      <mat-divider class="block-divider"></mat-divider>

      <div class="company-info second-page">
        <img src="../../../assets/DAO-logo-1.png" alt="dao_logo">
        <div class="website-container">
          Website<br>
          <span>www.dao.com</span>
        </div>
        <div class="email-container">
          Email<br>
          <span>John@dao.com</span>
        </div>
      </div>

      <div class="project-info-title">
        THE ANCHOR
      </div>
      <div class="project-info-text">
        Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense Limpsen latin nonsense
      </div>
      <div class="block-row">
        <div class="block-title">
          Estimated Cash Flow (in US Dollars)
        </div>
      </div>
      <mat-divider class="block-divider"></mat-divider>

      <div class="linechart-container">
        <canvas baseChart width="400" height="200"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
        </canvas>
      </div>
      <div class="linechart-legend-container">
        <div class="legend-item">
          <mat-icon class="electricity-icon">circle</mat-icon>
          Electricity sales
        </div>
        <div class="legend-item">
          <mat-icon class="cost-icon">circle</mat-icon>
          Cost of Sales
        </div>
        <div class="legend-item">
          <mat-icon class="net-icon">circle</mat-icon>
          Net CF
        </div>
      </div>
    </div>
    <div class="fact-sheet-right-column">
      <div class="block-row">
        <div class="block-title">
          Investment
        </div>
      </div>
      <mat-divider class="block-divider"></mat-divider>
      <div class="project-info-item">
        <div class="project-info-title">
          INVESTMENT/LOAN TYPE:
        </div>
        <div class="project-info-text">
          Equity
        </div>
      </div>
      <div class="project-info-item">
        <div class="project-info-title mt">
          BUSINESS MODEL:
        </div>
        <div class="project-info-text">
          Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense
        </div>
      </div>
      <div class="project-info-item">
        <div class="project-info-title mt">
          CUSTOMER ACQUISITION:
        </div>
        <div class="project-info-text">
          Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense Lipsem latin nonsense
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sign-container">
  <span class="sign-text">
    Empowered by
  </span>
  <img
    src="../../../assets/h-fullcolor-1.png"
    class="logo"
    alt="logo"
  />
</div>
