<div class="simulations-wrapper">
  <div class="simulations-column-wrapper">
    <div class="simulations-header">
      <h1>Your Simulations</h1>
    </div>
    <div class="simulations-cards-wrapper" *ngIf="simulations?.length">
      <mat-card class="simulation-button-card" *ngFor="let sim of simulations" (click)="openSimulation(sim.id)">
        <h2>{{sim.name}}</h2>
        <mat-icon class="simulation-next-icon">navigate_next</mat-icon>
      </mat-card>
      <button mat-button class="create-simulation-button" (click)="openSimulation()">
        Create New Simulation
      </button>
    </div>
    <div class="no-simulations-wrapper" *ngIf="!simulations?.length">
      You have no any simulations.
      <button mat-button class="create-simulation-button" (click)="openSimulation()">
        Create New Simulation
      </button>
    </div>
  </div>

  <div class="profile-column-wrapper">
    <div class="simulations-header">
      <h1>Profile</h1>
    </div>
    <mat-card class="profile-card">
      <div class="user-info-wrapper">
        <h2>Name</h2>
        <p>User Name</p>
        <h2>Email</h2>
        <p>user@email.com</p>
        <h2>Location</h2>
        <p class="location-paragraph">
          <mat-icon class="location-icon">place</mat-icon>
          Caguas, Puerto Rico
        </p>
        <h2>User's bio</h2>
        <p>Some other information</p>
      </div>
      <mat-icon class="settings-icon" (click)="openUserPreferencesDialog()">settings</mat-icon>
    </mat-card>
  </div>
</div>
