import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "../auth/auth.service";

export const AUTH_PAGES = [
  '/auth/login',
  '/auth/register',
]

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // const isAuthPage = AUTH_PAGES.some(url => state.url.startsWith(url));
    // if (this.authService.isAuthenticated()) {
    //   if (!isAuthPage) {
    //     return true;
    //   }
    //   this.router.navigate(['project-progress']);
    //   return false;
    // }
    // if (isAuthPage) {
    //   return true;
    // }
    // this.router.navigate(['auth']);
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
