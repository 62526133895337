import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from "../constants";
import { AuthService } from "../auth/auth.service";

export const ENDS_WITH_BASIC_AUTH = [
  '/auth/register/email',
  '/auth/register/email/verify/resend',
  '/auth/login/email',
];


@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isBasicAuth = ENDS_WITH_BASIC_AUTH.some(url => url === request.url.split(API_URL)[1]);
    if (isBasicAuth) {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json',
          Authorization: 'Basic ' + btoa(this.authService.authData + ':')
        }
      });
    }

    return next.handle(request);
  }
}
