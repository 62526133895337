import {Component, OnInit, ViewChild} from '@angular/core';
import {
  breakdown,
  consumptionChartOptions,
  consumptionDataMapping,
  consumptionLabelsMapping,
  consumptionTimePeriodsMapping,
} from './data';
import {ChartDataSets, ChartType} from "chart.js";
import 'chart.js';
import 'chartjs-plugin-dragdata';
import {BaseChartDirective, Label, SingleDataSet} from 'ng2-charts';
import {GridDataIntervalEnum, gridDataIntervalsMapping} from "./grid-data.enum";

@Component({
  selector: 'app-grid-data-dashboard',
  templateUrl: './grid-data-dashboard.component.html',
  styleUrls: ['./grid-data-dashboard.component.scss'],
})
export class GridDataDashboardComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  selected = 'Daily';
  intervalSliderValue = GridDataIntervalEnum.DAILY;

  consumptionChartData: ChartDataSets[] = [];
  consumptionChartOptions: any = {};
  consumptionChartLabels: Label[] = [];
  consumptionChartType: ChartType = 'bar';

  breakdownChartLabels: Label[] = ['Residential', 'Commercial', 'Manufactures', 'Other'];
  breakdownChartData: SingleDataSet = [];
  breakdownChartType: ChartType = 'doughnut';
  breakdownChartOptions: any = { cutoutPercentage: 60 };
  breakdownChartColors: any = [
    {
      backgroundColor: ['#54b1f8', '#5d4fea', '#e7884f', '#df4a4f'],
      borderColor: ['#54b1f8', '#5d4fea', '#e7884f', '#df4a4f'],
      borderWidth: 1
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
    this.consumptionChartOptions = {
      ...consumptionChartOptions,
      dragData: true,
      dragX: false,
      dragDataRound: 10,
      onDragEnd: () => {
        console.log('New y-value is ', this.chart?.chart.config.data?.datasets);
      },
    };
    this.consumptionChartData = consumptionDataMapping[this.intervalSliderValue];
    this.consumptionChartLabels = consumptionLabelsMapping[this.intervalSliderValue];

    this.breakdownChartData = breakdown;
  }

  getIntervalTitle(): string {
    return gridDataIntervalsMapping[this.intervalSliderValue];
  }

  getIntervalPeriodText(): string {
    return consumptionTimePeriodsMapping[this.intervalSliderValue];
  }

  changeDataByInterval(): void {
    this.consumptionChartData = consumptionDataMapping[this.intervalSliderValue];
    this.consumptionChartLabels = consumptionLabelsMapping[this.intervalSliderValue];
  }

}
