import { Component, Input, OnInit } from '@angular/core';
import { InviteDialogComponent } from "../dialogs/invite-dialog/invite-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-container-header',
  templateUrl: './container-header.component.html',
  styleUrls: ['./container-header.component.scss']
})
export class ContainerHeaderComponent implements OnInit {
  @Input() title: string[];

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  openInviteDialog(): void {
    this.dialog.open(InviteDialogComponent, {
      panelClass: 'invite-dialog',
      width: '870px',
    });
  }

}
