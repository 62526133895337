import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LoadDemandReviewDialogComponent } from "../load-demand-review-dialog/load-demand-review-dialog.component";

@Component({
  selector: 'app-completed-dialog',
  templateUrl: './completed-dialog.component.html',
  styleUrls: ['./completed-dialog.component.scss']
})
export class CompletedDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { fromReview: boolean, title: string, reviewUrl: string },
    public dialogRef: MatDialogRef<CompletedDialogComponent>,
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  navigateTo(toReview = false): void {
    toReview
      ? this.router.navigate(['project-progress', this.data.reviewUrl])
      : this.router.navigate(['project-progress']);
    this.closeDialog();
  }

  openPreviewDialog(): void {
    this.dialogRef.close();
    this.dialog.open(LoadDemandReviewDialogComponent, {
      panelClass: 'load-demand-review-dialog',
      width: '1280px',
    });
  }

}
