import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-user-preferences-dialog',
  templateUrl: './user-preferences-dialog.component.html',
  styleUrls: ['./user-preferences-dialog.component.scss']
})
export class UserPreferencesDialogComponent implements OnInit {
  userForm!: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<UserPreferencesDialogComponent>,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      user_name: [''],
      email: [''],
      location: [''],
      user_bio: [''],
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
