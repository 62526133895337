<div class="edit-assumptions-dialog-wrapper">
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
  <form [formGroup]="assumptionsForm" class="assumptions-form">
    <ng-container [ngSwitch]="item">
      <ng-template ngSwitchCase="systemCapexAssumptions">
        <div class="financials-input-header">
          System Capex
          <mat-icon>chevron_right</mat-icon>
          <span>
            Set Assumptions
          </span>
        </div>
        <div class="input-info">*Customize all that apply</div>
        <div class="field-container">
          <div class="field-label">
            Solar PV equipment + install ($/W)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('solarPvEquipment')">-</div>
              <input matInput formControlName="solarPvEquipment">
              <div class="inc-button" (click)="incValue('solarPvEquipment')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Solar degradation over 30 years (%)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('solarDegradation')">-</div>
              <input matInput formControlName="solarDegradation">
              <div class="inc-button" (click)="incValue('solarDegradation')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Storage to solar ratio
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('storageSolar')">-</div>
              <input matInput formControlName="storageSolar">
              <div class="inc-button" (click)="incValue('storageSolar')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Battery storage system + install ($/MWh)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('batteryStorageSystem')">-</div>
              <input matInput formControlName="batteryStorageSystem">
              <div class="inc-button" (click)="incValue('batteryStorageSystem')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            CHP/Cogen/Engine lease cost ($/kWh)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('chpCogenEngineLeaseCost')">-</div>
              <input matInput formControlName="chpCogenEngineLeaseCost">
              <div class="inc-button" (click)="incValue('chpCogenEngineLeaseCost')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Fuel cost ($/kWh)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('fuelCost')">-</div>
              <input matInput formControlName="fuelCost">
              <div class="inc-button" (click)="incValue('fuelCost')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            System average longevity (years)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('averageLongevity')">-</div>
              <input matInput formControlName="averageLongevity">
              <div class="inc-button" (click)="incValue('averageLongevity')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Average equipment lease term (years)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('averageEquipmentLeaseTerm')">-</div>
              <input matInput formControlName="averageEquipmentLeaseTerm">
              <div class="inc-button" (click)="incValue('averageEquipmentLeaseTerm')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Backup to primary generation ratio (%)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('backupToPrimaryRatio')">-</div>
              <input matInput formControlName="backupToPrimaryRatio">
              <div class="inc-button" (click)="incValue('backupToPrimaryRatio')">+</div>
            </mat-form-field>
          </div>
        </div>

        <div class="estimated-block">
          <div class="estimated-title">Estimated % of total capex</div>
          <div class="estimated-value">75%</div>
        </div>
      </ng-template>
      <ng-template ngSwitchCase="epcCapexAssumptions">
        <div class="financials-input-header">
          EPC Capex
          <mat-icon>chevron_right</mat-icon>
          <span>
            Set Assumptions
          </span>
        </div>
        <div class="field-container">
          <div class="field-label">
            Site preparation including survey & topographic study
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('sitePreparation')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="sitePreparation"
              >
              <div class="inc-button" (click)="incValue('sitePreparation')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Technical consultants
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('technicalConsultants')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="technicalConsultants"
              >
              <div class="inc-button" (click)="incValue('technicalConsultants')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Reactive power inverters for standalone grid
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('reactivePowerInverters')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="reactivePowerInverters"
              >
              <div class="inc-button" (click)="incValue('reactivePowerInverters')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Smart meters (as required for DexGrid operations)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('smartMeters')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="smartMeters"
              >
              <div class="inc-button" (click)="incValue('smartMeters')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Transfer switches
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('transferSwitches')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="transferSwitches"
              >
              <div class="inc-button" (click)="incValue('transferSwitches')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Remote breakers
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('remoteBreakers')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="remoteBreakers"
              >
              <div class="inc-button" (click)="incValue('remoteBreakers')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            DexGrid services (contracted first 3 years)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('dexgridServices')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="dexgridServices"
              >
              <div class="inc-button" (click)="incValue('dexgridServices')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Microgrid control center
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('microgridControlCenter')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="microgridControlCenter"
              >
              <div class="inc-button" (click)="incValue('microgridControlCenter')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Standalone distribution grid
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('standaloneDistributionGrid')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="standaloneDistributionGrid"
              >
              <div class="inc-button" (click)="incValue('standaloneDistributionGrid')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Others
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('others')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="others"
              >
              <div class="inc-button" (click)="incValue('others')">+</div>
            </mat-form-field>
          </div>
        </div>

        <div class="estimated-block">
          <div class="estimated-title">Estimated % of total capex</div>
          <div class="estimated-value">75%</div>
        </div>
      </ng-template>
      <ng-template ngSwitchCase="opexAssumptions">
        <div class="financials-input-header">
          Opex
          <mat-icon>chevron_right</mat-icon>
          <span>
            Set Assumptions
          </span>
        </div>
        <div class="field-container">
          <div class="field-label">
            Standalone grid lease ($/kWh)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('standaloneGridLease')">-</div>
              <input matInput formControlName="standaloneGridLease">
              <div class="inc-button" (click)="incValue('standaloneGridLease')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            DexGrid post contracted period ($/kWh)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('dexgridPostContractedPeriod')">-</div>
              <input matInput formControlName="dexgridPostContractedPeriod">
              <div class="inc-button" (click)="incValue('dexgridPostContractedPeriod')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Land leasing ($/Sqft)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('landLeasing')">-</div>
              <input matInput formControlName="landLeasing">
              <div class="inc-button" (click)="incValue('landLeasing')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Fuel generation capacity ($/kWh)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('fuelGenerationCapacity')">-</div>
              <input matInput formControlName="fuelGenerationCapacity">
              <div class="inc-button" (click)="incValue('fuelGenerationCapacity')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Management staff (annually)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('managementStaff')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="managementStaff"
              >
              <div class="inc-button" (click)="incValue('managementStaff')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Legal & accounting (annually)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('legalAndAccounting')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="legalAndAccounting"
              >
              <div class="inc-button" (click)="incValue('legalAndAccounting')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Operator (annually)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('operator')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="operator"
              >
              <div class="inc-button" (click)="incValue('operator')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Security (annually)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('security')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="security"
              >
              <div class="inc-button" (click)="incValue('security')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Insurance (annually)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('insurance')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="insurance"
              >
              <div class="inc-button" (click)="incValue('insurance')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Others
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('others')">-</div>
              <input
                matInput
                currencyMask
                [options]="{ align: 'center' }"
                formControlName="others"
              >
              <div class="inc-button" (click)="incValue('others')">+</div>
            </mat-form-field>
          </div>
        </div>

        <div class="estimated-block">
          <div class="estimated-title">Estimated annual opex</div>
          <div class="estimated-value">$95,000</div>
        </div>
      </ng-template>
      <ng-template ngSwitchCase="otherItemsAssumptions">
        <div class="financials-input-header">
          Others
          <mat-icon>chevron_right</mat-icon>
          <span>
            Set Assumptions
          </span>
        </div>
        <div class="field-container">
          <div class="field-label">
            Inflation (%)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('inflation')">-</div>
              <input matInput formControlName="inflation">
              <div class="inc-button" (click)="incValue('inflation')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Discount rate (%)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('discountRate')">-</div>
              <input matInput formControlName="discountRate">
              <div class="inc-button" (click)="incValue('discountRate')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Consumer rate ($/kWh)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('consumerRate')">-</div>
              <input matInput formControlName="consumerRate">
              <div class="inc-button" (click)="incValue('consumerRate')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Total solar Investment Tax Credit (% of system cost)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('investmentTaxCredit')">-</div>
              <input matInput formControlName="investmentTaxCredit">
              <div class="inc-button" (click)="incValue('investmentTaxCredit')">+</div>
            </mat-form-field>
          </div>
        </div>
        <div class="field-container">
          <div class="field-label">
            Renewable Energy Certificates RECs ($/kWh)
          </div>
          <div class="field-input-container">
            <mat-form-field appearance="outline" class="form-field">
              <div class="dec-button" (click)="decValue('renewableEnergyCertificatesRecs')">-</div>
              <input matInput formControlName="renewableEnergyCertificatesRecs">
              <div class="inc-button" (click)="incValue('renewableEnergyCertificatesRecs')">+</div>
            </mat-form-field>
          </div>
        </div>

        <div class="estimated-block">
          <div class="estimated-title">
            Others estimated impact on project cost
          </div>
          <div class="estimated-value">+/-0.00%</div>
        </div>
      </ng-template>
    </ng-container>
  </form>
  <div class="financials-inputs-actions">
    <button
      mat-button
      class="reset-button"
      (click)="closeDialog()"
    >
      Cancel
    </button>
    <button
      mat-button
      class="save-button"
      (click)="save()"
    >
      Save changes
    </button>
  </div>
</div>
