import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { CapexOpexData } from "../../models/capex-opex-dashboard";
import { ChartDataSets, ChartType } from "chart.js";
import { Color, Label, SingleDataSet } from "ng2-charts";

@Component({
  selector: 'app-factsheet-review-dialog',
  templateUrl: './factsheet-review-dialog.component.html',
  styleUrls: ['./factsheet-review-dialog.component.scss']
})
export class FactsheetReviewDialogComponent implements OnInit {
  isFirstPage = true;
  capexOpexData: CapexOpexData;
  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'];
  lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: '#CBCBCB',
          },
          ticks: {
            stepSize : 1,
            max: 5,
            min: 0,
            beginAtZero: true,
            callback: this.formatFinance
          },
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        }
      ]
    },
    legend: {
      position: 'top',
      labels: {
        fontSize: 14,
        fontColor: '#ffffff',
        usePointStyle: true
      }
    }
  };
  lineChartColors: Color[] = [
    {
      borderColor: 'rgb(254, 165, 99)',
      backgroundColor: 'rgba(254, 165, 99, 0.1)',
    },
    {
      borderColor: 'rgb(152, 64, 221)',
      backgroundColor: 'rgba(152, 64, 221, 0.1)',
    },
    {
      borderColor: 'rgb(0, 122, 255)',
      backgroundColor: 'rgba(0, 122, 255, 0.1)',
    },
  ];
  lineChartLegend = false;
  lineChartType: ChartType = 'line';
  lineChartPlugins = [];

  generationCapacityChartLabels: Label[] = ['Primary', 'Backup'];
  generationCapacityChartData: SingleDataSet = [];
  generationCapacityChartType: ChartType = 'doughnut';
  generationCapacityChartOptions: any = { cutoutPercentage: 60, rotation: Math.PI };
  generationCapacityChartColors: any = [
    {
      backgroundColor: ['#F08035', '#9840DD'],
      borderColor: ['#F08035', '#9840DD'],
      borderWidth: 1
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<FactsheetReviewDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    this.capexOpexData = {
      estimatedCashFlow: {
        electricitySales: [0.6, 2, 2.5, 3.5, 4.1],
        costOfSales: [1, 1.2, 1.5, 1.7, 2.1, 2.5],
        netCf: [1, 1.5, 2, 2.7, 3.5],
      },
      generationCapacity: {
        primaryGeneration: 500,
        primarySolar: 400,
        primaryBatteries: 840,
        primaryEngine: 100,
        backupGeneration: 500,
        backupEngine: 400,
      },
      keyNumbers: {
        totalInvestment: 1.5,
        irr: 35,
        npv: 3.1,
        itcDiscount: 250000,
        recRevenue: 95000,
        totalAnnualLoanRepayment: 145000,
        totalAnnualProduction: 85000,
        totalAnnualConsumption: 83000,
        system: 75,
        epc: 25,
        annualOpex: 80000,
      }
    };

    this.lineChartData = [
      { data: this.capexOpexData.estimatedCashFlow.electricitySales, label: 'Electricity Sales' },
      { data: this.capexOpexData.estimatedCashFlow.costOfSales, label: 'Cost of Sales' },
      { data: this.capexOpexData.estimatedCashFlow.netCf, label: 'Net CF' },
    ];

    this.generationCapacityChartData = [
      this.capexOpexData.generationCapacity.primaryGeneration,
      this.capexOpexData.generationCapacity.backupGeneration,
    ];
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  formatFinance(value: unknown): string {
    return value + ' MM';
  }

}
