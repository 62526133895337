<h1 mat-dialog-title>Share</h1>
<div mat-dialog-content>
  <div class="toggle-sharing-container">
    <p class="toggle-sharing-paragraph">Public sharing</p>
    <mat-slide-toggle
      class="example-margin"
      [(ngModel)]="sharingEnabled"
      (change)="onEnableInput($event)"
    >
    </mat-slide-toggle>
  </div>
  <div class="link-settings-container" *ngIf="sharingEnabled">
    <div class="action-settings-container">
      <p>
        <mat-icon class="link-icon">link</mat-icon>
        Public link
      </p>
      <mat-select
        [(value)]="sharingMode"
        [disabled]="!!data?.simulationId"
        class="link-action-select"
      >
        <mat-option value="view">
          View
        </mat-option>
        <mat-divider></mat-divider>
        <mat-option value="edit">
          Edit
        </mat-option>
      </mat-select>
    </div>
    <div class="link-container">
      <div class="link-text">
        {{link}}
      </div>
      <a
        class="copy-link"
        [cdkCopyToClipboard]="link"
        (cdkCopyToClipboardCopied)="changeCopyLinkTitle()"
      >
        {{copyLinkButtonTitle}}
      </a>
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog-footer">
  <button mat-button class="dialog-button" (click)="cancel()">Cancel</button>
  <button mat-button class="dialog-button" (click)="save()">Save</button>
</div>
