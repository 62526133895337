import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { PROGRESS_TREE, ProgressFlatNode, ProgressNode } from "../models/progress-node";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { ReachUsDialogComponent } from "../dialogs/reach-us-dialog/reach-us-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ProgressStatusEnum, progressStatusesMapping } from "../progress/progress.enum";
import { ProgressService } from "../progress/progress.service";
import { ProjectsManagerDialogComponent } from "../dialogs/projects-manager-dialog/projects-manager-dialog.component";

export const PAGES_WITHOUT_SIDEBAR = [
  '/auth',
  '/auth/login',
  '/auth/register',
]

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showSidebar$: Observable<boolean>;
  expandedMenu = false;
  status: ProgressStatusEnum = ProgressStatusEnum.LOAD_DEMAND;
  private readonly destroyed$: Subject<void> = new Subject<void>();
  private _transformer = (node: ProgressNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      path: node.path,
    };
  }

  treeControl = new FlatTreeControl<ProgressFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private progressService: ProgressService,
  ) {
    this.dataSource.data = PROGRESS_TREE;
  }

  hasChild = (_: number, node: ProgressFlatNode) => node.expandable;

  ngOnInit(): void {
    this.progressService.progressStatus$.subscribe(status => {
      this.status = status;
      this.treeControl.collapseAll();
    });
    this.showSidebar$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        return !PAGES_WITHOUT_SIDEBAR.some(page => this.router.url.startsWith(page))
      }),
      takeUntil(this.destroyed$),
    );
  }

  expandMenu(): void {
    this.expandedMenu = !this.expandedMenu;
  }

  openReachUsDialog(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.dialog.open(ReachUsDialogComponent, {
      width: '300px',
    });
  }

  onNodeClick(node: ProgressFlatNode) {
    if (node.level === 0) {
      if (this.isAvailableNode(node)) {
        if (!this.treeControl.isExpanded(node)) {
          this.treeControl.collapse(node);
        } else {
          this.treeControl.collapseAll();
          this.treeControl.expand(node);
        }
      } else {
        this.treeControl.collapse(node);
      }
      return;
    }
    this.treeControl.isExpanded(node) ? this.treeControl.expand(node) : this.treeControl.collapse(node);
  }

  isAvailableNode(node: ProgressFlatNode): boolean {
    const progressStep = Object.keys(progressStatusesMapping).find(key => progressStatusesMapping[key] === node.name);
    return this.status >= +progressStep;
  }

  onNavigate(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    window.open('https://dexgrid.io/', "_blank");
  }

  navigateToProgressItem(path: ProgressNode): void {
    if (path.path) {
      this.router.navigateByUrl(path.path);
    }
  }

  openProjectsManagerDialog(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.dialog.open(ProjectsManagerDialogComponent, {
      panelClass: 'projects-manager-dialog',
      width: '870px',
    });
  }

}
