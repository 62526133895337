import { Component, OnInit } from '@angular/core';
import { OperationSimDashboard, OperationSimDrProgram } from "../models/operation-sim-dashboard";
import { ChartDataSets, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import { consumptionLabelsMapping } from "../grid-data-dashboard/data";
import { GridDataIntervalEnum } from "../grid-data-dashboard/grid-data.enum";
import { ShareDialogComponent } from "../dialogs/share-dialog/share-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-operations-sim-dashboard',
  templateUrl: './operations-sim-dashboard.component.html',
  styleUrls: ['./operations-sim-dashboard.component.scss']
})
export class OperationsSimDashboardComponent implements OnInit {
  operationSimDashboard: OperationSimDashboard;
  demandResponseProgram: OperationSimDrProgram;
  selected: string = 'daily';
  hourlyChartData: ChartDataSets[] = [];
  dailyChartData: ChartDataSets[] = [];
  monthlyChartData: ChartDataSets[] = [];
  hourlyChartLabels: Label[] = consumptionLabelsMapping[GridDataIntervalEnum.HOURLY];
  dailyChartLabels: Label[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  monthlyChartLabels: Label[] = consumptionLabelsMapping[GridDataIntervalEnum.MONTHLY];
  lineChartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: '#545e75',
          },
        }
      ],
      xAxes: [
        {
          gridLines: {
            color: '#262b46',
          },
          ticks: {
            beginAtZero: true,
          },
        }
      ]
    },
    legend: {
      position: 'top',
      labels: {
        fontSize: 14,
        fontColor: '#ffffff',
        usePointStyle: true
      }
    }
  };
  lineChartColors: Color[] = [
    {
      borderColor: 'rgb(241, 169, 111)',
      backgroundColor: 'rgba(241, 169, 111, 0.1)',
    },
    {
      borderColor: 'rgb(222, 72, 83)',
      backgroundColor: 'rgba(222, 72, 83, 0.1)',
    },
    {
      borderColor: 'rgb(79, 100, 179)',
      backgroundColor: 'rgba(79, 100, 179, 0.1)',
    },
  ];
  lineChartLegend = true;
  lineChartType: ChartType = 'line';

  constructor(
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.demandResponseProgram = {
      anchorEconomicPerformance: '+$25000',
      averageConsumerCost: '-$45',
      averageConsumerEcDuration: '',
      averageConsumerSavings: '+30%',
      emergencyBackupGeneration: '-27%',
      individualConsumerEcDuration: '',
      individualConsumptionCostOverPeriod: '-$50',
      individualConsumptionOverPeriod: '+0%',
      microgridStress: '-25%',
      reliabilityRateOverPeriod: '+0%',
      savingsFromDrProgram: '+31%'
    }
    this.operationSimDashboard = {
      totalCommunitySavings: {
        hourly: [50, 100, 150, 250, 300, 350, 400, 50, 100, 150, 250, 300, 350, 400, 50, 100, 150, 250, 300, 350, 400, 50, 100, 150],
        daily: [50, 100, 150, 250, 300, 350, 400],
        monthly: [50, 100, 150, 250, 300, 350, 400, 50, 100, 150, 250, 300],
      },
      averageReliabilityRate: {
        hourly: [110, 120, 150, 190, 200, 250, 260, 110, 120, 150, 190, 200, 250, 260, 110, 120, 150, 190, 200, 250, 260, 110, 120, 150],
        daily: [110, 120, 150, 190, 200, 250, 260],
        monthly: [110, 120, 150, 190, 200, 250, 260, 110, 120, 150, 190, 200],
      },
      stressCondition: {
        hourly: [100, 110, 160, 200, 250, 300, 350, 100, 110, 160, 200, 250, 300, 350, 100, 110, 160, 200, 250, 300, 350, 100, 110, 160],
        daily: [100, 110, 160, 200, 250, 300, 350],
        monthly: [100, 110, 160, 200, 250, 300, 350, 100, 110, 160, 200, 250],
      },
      demandResponseProgram: {
        hourly: {
          ...this.demandResponseProgram,
          averageConsumerEcDuration: '+24 hours',
          individualConsumerEcDuration: '+24 hours',
        },
        daily: {
          ...this.demandResponseProgram,
          averageConsumerEcDuration: '+7 days',
          individualConsumerEcDuration: '+7 days',
        },
        monthly: {
          ...this.demandResponseProgram,
          averageConsumerEcDuration: '+12 months',
          individualConsumerEcDuration: '+12 months',
        },
      }
    }

    this.hourlyChartData = [
      { data: this.operationSimDashboard.totalCommunitySavings.hourly, label: 'Total Community Savings' },
      { data: this.operationSimDashboard.averageReliabilityRate.hourly, label: 'Average Reliability Rate' },
      { data: this.operationSimDashboard.stressCondition.hourly, label: 'Stress Condition' },
    ];

    this.dailyChartData = [
      { data: this.operationSimDashboard.totalCommunitySavings.daily, label: 'Total Community Savings' },
      { data: this.operationSimDashboard.averageReliabilityRate.daily, label: 'Average Reliability Rate' },
      { data: this.operationSimDashboard.stressCondition.daily, label: 'Stress Condition' },
    ];

    this.monthlyChartData = [
      { data: this.operationSimDashboard.totalCommunitySavings.monthly, label: 'Total Community Savings' },
      { data: this.operationSimDashboard.averageReliabilityRate.monthly, label: 'Average Reliability Rate' },
      { data: this.operationSimDashboard.stressCondition.monthly, label: 'Stress Condition' },
    ];
  }

  openShareDialog(): void {
    this.matDialog.open(ShareDialogComponent, {
      width: '350px',
      panelClass: 'share-modal',
    });
  }

}
