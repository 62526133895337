<div class="ld-completed-container">
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
  <img
    src="../../../assets/circle-checked.png"
    class="circle-checked-img"
    alt="circle_checked_img"
  />
  <div class="ld-completed-title">
    {{ data.title }} <br>
    completed
  </div>
  <div class="ld-completed-info">
    Ready for preview
  </div>
  <div class="actions-container" *ngIf="!data.fromReview">
    <button mat-button class="review-button" (click)="navigateTo(true)">
      Review Project
    </button>
    <div class="about-review">
      You’ll be able to make changes and preview<br>
      the result before sharing it with people.
    </div>
  </div>
  <div class="review-actions-container" *ngIf="data.fromReview">
    <div class="buttons-row">
      <button mat-button class="review-button" (click)="closeDialog()">
        Back to Review
      </button>
      <button mat-button class="share-button" (click)="openPreviewDialog()">
        Share result
      </button>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="progress-button" (click)="navigateTo()">
      Project Progress
    </div>
  </div>
</div>
