<div class="financials-review-dialog-header">
  <div class="dialog-title">
    PREVIEW MODE<br>
    <span>You are previewing how your guest will see the final results of load demand</span>
  </div>
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
</div>

<div class="financials-preview-header">
  <div class="financials-preview-header-title">
    <span class="preview-header">Welcome!</span>
    <span class="company-name">DAO Farm</span>
    <div class="logo-button">
      <img
        src="../../../assets/LOGO.png"
        class="logo-button-title"
        alt="logo"
      />
      <div class="add-circle-container">
        <img
          src="../../../assets/add-circle.png"
          class="add-icon"
          alt="add_logo"
        />
      </div>
    </div>
  </div>
  <mat-divider class="header-divider"></mat-divider>
  <div class="financials-preview-header-actions">
    <button mat-button class="download-button">
      <mat-icon>file_download</mat-icon>&nbsp;
      Download
    </button>
    <button mat-button class="print-button">
      <mat-icon>print</mat-icon>&nbsp;
      Print
    </button>
    <button mat-button class="share-button">
      <mat-icon>share</mat-icon>&nbsp;
      Share to show result
    </button>
  </div>
</div>

<div class="financials-preview-dialog-container">
  <div class="financials-left-column">
    <div class="block-row">
      <div class="block-title">Generation capacity</div>
      <img
        src="../../../assets/edit-icon.png"
        class="edit-icon"
        alt="edit_icon"
      />
    </div>
    <div class="block-row">
      <div class="generation-capacity-container">
        <div class="chart-container">
          <canvas
            baseChart
            width="190"
            height="190"
            class="breakdown-chart"
            [data]="generationCapacityChartData"
            [labels]="generationCapacityChartLabels"
            [chartType]="generationCapacityChartType"
            [legend]="false"
            [colors]="generationCapacityChartColors"
            [options]="generationCapacityChartOptions"
          >
          </canvas>
        </div>
        <div class="legend-container">
          <div class="legend-row">
            <mat-icon class="primary-generation-icon">circle</mat-icon>
            <div class="legend-row-name">Primary generation</div>
            <div class="legend-row-value">{{this.capexOpexData.generationCapacity.primaryGeneration.totalKW}} kW</div>
          </div>
          <div class="sub-legend-row" *ngFor="let method of this.capexOpexData.generationCapacity.primaryGeneration.methods">
            <div class="legend-row-name">{{method.name}}</div>
            <div class="legend-row-value">{{method.kW}} kW</div>
          </div>
          <div class="legend-row">
            <mat-icon class="backup-generation-icon">circle</mat-icon>
            <div class="legend-row-name">Backup generation</div>
            <div class="legend-row-value">{{this.capexOpexData.generationCapacity.backupGeneration.totalKW}} kW</div>
          </div>
          <div class="sub-legend-row" *ngFor="let method of this.capexOpexData.generationCapacity.backupGeneration.methods">
            <div class="legend-row-name">{{method.name}}</div>
            <div class="legend-row-value">{{method.kW}} kW</div>
          </div>
        </div>
        <div class="info-container">
          <div class="info-icon-container">
            <mat-icon class="info-icon">info</mat-icon>
            <div class="info-tooltip">
              Balance (in %) between primary and backup generation capacity under normal operations
            </div>
          </div>
          <div class="primary-value">(80%)</div>
          <div class="info-icon-container backup">
            <mat-icon class="info-icon">info</mat-icon>
            <div class="info-tooltip">
              Balance (in %) between primary and backup generation capacity under normal operations
            </div>
          </div>
          <div class="backup-value">(20%)</div>
        </div>
      </div>
    </div>
    <mat-divider class="h-line" [vertical]="true"></mat-divider>
    <div class="block-row">
      <div class="block-title">Estimated Cash Flow (in US Dollars)</div>
      <img
        src="../../../assets/edit-icon.png"
        class="edit-icon"
        alt="edit_icon"
      />
    </div>
    <div class="linechart-container">
      <canvas
        baseChart
        width="400"
        height="200"
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins"
      >
      </canvas>
    </div>
    <div class="legend-container">
      <div class="legend-item">
        <mat-icon class="electricity-icon">circle</mat-icon>
        Electricity sales
      </div>
      <div class="legend-item">
        <mat-icon class="cost-icon">circle</mat-icon>
        Cost of Sales
      </div>
      <div class="legend-item">
        <mat-icon class="net-icon">circle</mat-icon>
        Net CF
      </div>
    </div>
  </div>
  <mat-divider class="v-line" [vertical]="true"></mat-divider>
  <div class="financials-right-column">
    <div class="block-row">
      <div class="block-title">
        Key numbers
        <span>Estimated</span>
      </div>
      <img
        src="../../../assets/edit-icon.png"
        class="edit-icon"
        alt="edit_icon"
      />
    </div>
    <mat-divider class="block-divider"></mat-divider>
    <div class="key-numbers-container">
      <div class="key-number-container">
        <p class="key-number-title text-bold">Total Investment</p>
        <p class="key-number-value text-bold">${{capexOpexData.keyNumbers.totalInvestment}} MM</p>
      </div>
      <div class="key-number-container">
        <p class="key-number-title text-16">IRR</p>
        <p class="key-number-value text-16">{{capexOpexData.keyNumbers.irr}}%</p>
      </div>
      <div class="key-number-container">
        <p class="key-number-title text-16">NPV</p>
        <p class="key-number-value text-16">${{capexOpexData.keyNumbers.npv}} MM</p>
      </div>
      <mat-divider class="block-divider"></mat-divider>
      <div class="key-number-container">
        <p class="key-number-title">System (% of total CAPEX)</p>
        <p class="key-number-value">{{capexOpexData.keyNumbers.system}}%</p>
      </div>
      <div class="key-number-container">
        <p class="key-number-title">EPC (% of total CAPEX)</p>
        <p class="key-number-value">{{capexOpexData.keyNumbers.epc}}%</p>
      </div>
      <div class="key-number-container">
        <p class="key-number-title">Annual OPEX</p>
        <p class="key-number-value">${{capexOpexData.keyNumbers.annualOpex | number}}</p>
      </div>
      <mat-divider class="block-divider"></mat-divider>
      <div class="key-number-container">
        <p class="key-number-title">Total annual production</p>
        <p class="key-number-value">{{capexOpexData.keyNumbers.totalAnnualProduction | number}} kWh</p>
      </div>
      <div class="key-number-container">
        <p class="key-number-title">Total annual consumption</p>
        <p class="key-number-value">{{capexOpexData.keyNumbers.totalAnnualConsumption | number}} kWh</p>
      </div>
      <mat-divider class="block-divider"></mat-divider>
      <div class="key-number-container">
        <p class="key-number-title">ITC discount</p>
        <p class="key-number-value">${{capexOpexData.keyNumbers.itcDiscount | number}}</p>
      </div>
      <div class="key-number-container">
        <p class="key-number-title">REC revenue</p>
        <p class="key-number-value">${{capexOpexData.keyNumbers.recRevenue | number}}</p>
      </div>
      <div class="key-number-container">
        <p class="key-number-title">Total annual loan repayment</p>
        <p class="key-number-value">${{capexOpexData.keyNumbers.totalAnnualLoanRepayment | number}}</p>
      </div>
    </div>
  </div>
</div>

<div class="sign-container">
  <span class="sign-text">
    Empowered by
  </span>
  <img
    src="../../../assets/h-fullcolor-1.png"
    class="logo"
    alt="logo"
  />
</div>
