import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SimulationService } from "../services/simulation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatSelectChange } from "@angular/material/select";
import { OldSimulation } from "../models/oldSimulation";
import { MatDialog } from "@angular/material/dialog";
import { DeleteDialogComponent } from "../dialogs/delete-dialog/delete-dialog.component";
import { ShareDialogComponent } from "../dialogs/share-dialog/share-dialog.component";

@Component({
  selector: 'app-run-program',
  templateUrl: './run-program.component.html',
  styleUrls: ['./run-program.component.scss']
})
export class RunProgramComponent implements OnInit {
  initialSimulation: OldSimulation;
  initialSimulationId: string;
  selectedStartDate;
  selectedEndDate;
  selectedStartTime: number;
  selectedEndTime: number;
  parametersForm: FormGroup;
  hours = ['1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM',
    '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM',
    '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM', '12:00 AM'];

  constructor(
    private formBuilder: FormBuilder,
    private simulationService: SimulationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.initialSimulationId = this.route.snapshot.queryParamMap.get('simId');
    if (this.initialSimulationId) {
      this.simulationService.getSimulationById(this.initialSimulationId).subscribe(
        (simulation: OldSimulation) => {
          this.initialSimulation = simulation;
          this.initParametersForm();
        },
        () => this.router.navigate(['simulations'])
      );
    } else {
      this.initParametersForm();
    }
  }

  initParametersForm(): void {
    if (this.initialSimulationId) {
      this.selectedStartDate = new Date(this.initialSimulation.startTime).toISOString();
      this.selectedEndDate = new Date(this.initialSimulation.endTime).toISOString();
      this.selectedStartTime = (new Date(this.initialSimulation.startTime)).getUTCHours();
      this.selectedEndTime = (new Date(this.initialSimulation.endTime)).getUTCHours();
    }
    this.parametersForm = this.formBuilder.group({
      name: [this.initialSimulation?.name || '', [Validators.required]],
      startTime: [this.initialSimulation?.startTime || '', [Validators.required]],
      endTime: [this.initialSimulation?.endTime || '', [Validators.required]],
      timeInterval: [this.initialSimulation?.timeInterval || 10, [Validators.required]],
      consumers: [this.initialSimulation?.consumers || 5, [Validators.required, Validators.min(5), Validators.max(40)]],
      region: [this.initialSimulation?.region || '', [Validators.required]],
      timePeriod: [this.initialSimulation?.timePeriod || '', Validators.required],
    });
  }

  onSubmit(): void {
    this.parametersForm.markAllAsTouched();

    if (this.parametersForm.valid) {
      if (this.initialSimulationId) {
        this.simulationService.updateSimulationOLD(this.initialSimulationId, this.parametersForm.value).subscribe(() => {
          this.router.navigate(['simulation-running']);
        })
      } else {
        this.simulationService.postSimulation(this.parametersForm.value).subscribe(() => {
          this.router.navigate(['simulation-running']);
        });
      }
    }
  }

  onDateInput(date: string, event: MatDatepickerInputEvent<any>): void {
    const newTime = new Date(date);
    newTime.setUTCHours(date === 'startTime' ? this.selectedStartTime : this.selectedEndTime);
    this.parametersForm.controls[date].patchValue(event.value.toISOString());
  }

  onTimeInput(date: string, event: MatSelectChange): void {
    const newTime = new Date(this.parametersForm.value[date]);
    newTime.setUTCHours(event.value);
    this.parametersForm.controls[date].patchValue(
      newTime.toISOString()
    );
    console.log(this.parametersForm.controls[date].value);
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      data: {
        simulationName: this.initialSimulation.name,
        onConfirm: () => {
          this.simulationService.deleteSimulation(this.initialSimulationId).subscribe(() =>{
            this.router.navigate(['simulations']);
          });
        },
      }
    });
  }

  openShareDialog(): void {
    this.dialog.open(ShareDialogComponent, {
      width: '350px',
      panelClass: 'share-modal',
      data: {
        simulationId: this.initialSimulationId,
      }
    });
  }

}
