export interface ProgressNode {
  name: string;
  children?: ProgressNode[];
  path?: string;
}

export interface ProgressFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  path: string;
}

export const PROGRESS_TREE: ProgressNode[] = [
  {
    name: 'Load demand',
    children: [
      {
        name: 'Project mapping',
        children: [
          { name: 'Company site' },
          { name: 'Consumers area', path: '/project-progress/project-mapping' },
        ],
      },
      {
        name: 'Consumers dashboard',
        children: [
          { name: 'Categories', path: '/project-progress/consumers-load-inputs' },
          { name: '# of consumers', path: '/project-progress/consumers-load-inputs' },
          { name: 'Preview project', path: '/project-progress/load-demand-review' },
        ],
      },
    ]
  },
  {
    name: 'Financials',
    children: [
      { name: 'Primary generation', path: '/project-progress/financials-dashboard' },
      { name: 'Cost items', path: '/project-progress/financials-dashboard' },
      { name: 'Preview', path: '/project-progress/financials-review' },
    ]
  },
  {
    name: 'Capital raising',
    children: [
      { name: 'Project info', path: '/project-progress/capital-raising' },
      { name: 'Anchor info', path: '/project-progress/capital-raising' },
      { name: 'Investment', path: '/project-progress/capital-raising' },
      { name: 'Key numbers', path: '/project-progress/capital-raising' },
      { name: 'Review', path: '/project-progress/factsheet-review' },
    ]
  },
  {
    name: 'Development',
    children: [
      { name: 'Technical consultant', path: '/project-progress/development?type=technical' },
      { name: 'Regulation expert', path: '/project-progress/development?type=regulation' },
      { name: 'EPC', path: '/project-progress/development?type=epc' },
      { name: 'Grid infrastructure', path: '/project-progress/development?type=grid' },
    ]
  },
  {
    name: 'Operations simulation',
    children: [
      { name: 'Simulation parameters', path: '/project-progress/operations-simulation' },
      { name: 'Review', path: '/project-progress/simulation-review' },
      { name: 'Demand Response\n Performance', path: '/project-progress/dr-performance' },
    ]
  },
  { name: 'Launch' },
];
