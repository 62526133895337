<h1 mat-dialog-title>User Preferences</h1>
<div mat-dialog-content>
  <form [formGroup]="userForm" class="user-form">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="user_name">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Location</mat-label>
      <input matInput formControlName="location">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>User's Bio</mat-label>
      <textarea matInput formControlName="user_bio"></textarea>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="dialog-footer">
  <button mat-button (click)="closeDialog()">Cancel</button>
  <button mat-button (click)="closeDialog()">Save</button>
</div>
