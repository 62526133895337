<div class="run-program-wrapper">
  <div class="run-program-container" *ngIf="simulation">
    <div class="simulation-header-block">
      <h2 class="run-program-title">Simulation Run Parameters</h2>
    </div>
    <mat-divider></mat-divider>
    <div class="simulation-name-container">
      <label>Simulation Name</label>
      <p class="value-paragraph">{{simulation.name}}</p>
    </div>
    <mat-divider></mat-divider>
    <div class="simulation-time-container">
      <div class="time-column">
        <label>Start time</label>
        <p class="value-paragraph">{{simulation.startTime | date:'medium'}}</p>
      </div>
      <div class="time-column">
        <label>End time</label>
        <p class="value-paragraph">{{simulation.endTime | date:'medium'}}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="consumers-amount-container">
      <div class="amount-container">
        <label>Amount of consumers</label>
        <p class="value-paragraph">{{simulation.consumers}}</p>
      </div>
      <div class="region-container">
        <label>Region</label>
        <p class="value-paragraph">{{simulation.region}}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="time-interval-container">
      <label>Time interval</label>
      <p class="value-paragraph">{{simulation.timeInterval}}</p>
    </div>
    <mat-divider></mat-divider>
    <div class="interval-length-container">
      <label>Simulation Interval Length</label>
      <p class="value-paragraph">{{simulation.timePeriod}}</p>
    </div>
  </div>

  <div class="error-container" *ngIf="error">You do not have access to view this simulation.</div>
</div>
