import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-page-info-dialog',
  templateUrl: './page-info-dialog.component.html',
  styleUrls: ['./page-info-dialog.component.scss']
})
export class PageInfoDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<PageInfoDialogComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
