import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ShareSimulationService } from "../../services/share-simulation.service";
import { SimulationShare } from "../../models/oldSimulation";
import { SimulationService } from "../../services/simulation.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit {
  sharingEnabled = false;
  sharingMode: 'view' | 'edit' = 'view';
  link = 'https://some-link';
  copyLinkButtonTitle = 'Copy link';
  simulationShare: SimulationShare;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { simulationId: string },
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    private shareSimulationService: ShareSimulationService,
    private simulationService: SimulationService,
  ) {
  }

  ngOnInit(): void {
    if (!this.data?.simulationId) {
      this.sharingEnabled = this.shareSimulationService.sharingEnabled;
      this.sharingMode = this.shareSimulationService.sharingMode;
    } else {
      this.simulationService.getSimShareLink(this.data.simulationId).subscribe(simulationShare => {
        this.simulationShare = simulationShare;
        this.sharingEnabled = true;
        this.link = simulationShare.link;
      });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  changeCopyLinkTitle(): void {
    this.copyLinkButtonTitle = 'Copied';
  }

  save(): void {
    if (!this.data?.simulationId) {
      this.shareSimulationService.setShareSettings(this.sharingEnabled, this.sharingMode);
    }
    this.dialogRef.close();
  }

  onEnableInput(event: MatSlideToggleChange): void {
    if (event.checked && !this.simulationShare) {
      this.simulationService.createSimShareLink(this.data.simulationId).subscribe(simulationShare => {
        this.simulationShare = simulationShare;
        this.sharingEnabled = true;
        this.link = simulationShare.link;
      });
    }
  }

}
