import { consumptionLabelsMapping } from "../../grid-data-dashboard/data";
import { GridDataIntervalEnum } from "../../grid-data-dashboard/grid-data.enum";

export const chartsData = {
  first: {
    [GridDataIntervalEnum.HOURLY]: {
      labels: consumptionLabelsMapping[GridDataIntervalEnum.HOURLY],
      data: [
        {
          data: [0, 60, 180, 230, 270, 330, 400, 0, 60, 180, 230, 270, 330, 400, 0, 60, 180, 230, 270, 330, 400, 0, 60, 180],
          label: 'Total community savings',
        },
        {
          data: [60, 100, 150, 190, 200, 280, 310, 60, 100, 150, 190, 200, 280, 310, 60, 100, 150, 190, 200, 280, 310, 60, 100, 150],
          label: 'Stress condition',
        },
        {
          data: [100, 110, 140, 170, 190, 200, 250, 100, 110, 140, 170, 190, 200, 250, 100, 110, 140, 170, 190, 200, 250, 100, 110, 140],
          label: 'Average reliability rate',
        },
      ],
    },
    [GridDataIntervalEnum.DAILY]: {
      labels: consumptionLabelsMapping[GridDataIntervalEnum.DAILY],
      data: [
        {
          data: [0, 60, 180, 230, 270, 330, 400],
          label: 'Total community savings',
        },
        {
          data: [60, 100, 150, 190, 200, 280, 310],
          label: 'Stress condition',
        },
        {
          data: [100, 110, 140, 170, 190, 200, 250],
          label: 'Average reliability rate',
        },
      ],
    },
    [GridDataIntervalEnum.MONTHLY]: {
      labels: consumptionLabelsMapping[GridDataIntervalEnum.MONTHLY],
      data: [
        {
          data: [0, 60, 180, 230, 270, 330, 400, 0, 60, 180, 230, 270],
          label: 'Total community savings',
        },
        {
          data: [60, 100, 150, 190, 200, 280, 310, 60, 100, 150, 190, 200],
          label: 'Stress condition',
        },
        {
          data: [100, 110, 140, 170, 190, 200, 250, 100, 110, 140, 170, 190],
          label: 'Average reliability rate',
        },
      ],
    },
  },
  second: {
    [GridDataIntervalEnum.HOURLY]: {
      labels: consumptionLabelsMapping[GridDataIntervalEnum.HOURLY],
      data: [
        {
          data: [0.3, 0.35, 0.57, 0.2, 0.24, 0.25, 0.37, 0.3, 0.35, 0.57, 0.2, 0.24, 0.25, 0.37, 0.3, 0.35, 0.57, 0.2, 0.24, 0.25, 0.37, 0.3, 0.35, 0.57],
          label: 'Demand curve',
        },
        {
          data: [0.08, 0.5, 1, 0.55, 0.2, 0.1, 0.1, 0.08, 0.5, 1, 0.55, 0.2, 0.1, 0.1, 0.08, 0.5, 1, 0.55, 0.2, 0.1, 0.1, 0.08, 0.5, 1],
          label: 'Solar yield curve',
        },
      ],
    },
    [GridDataIntervalEnum.DAILY]: {
      labels: consumptionLabelsMapping[GridDataIntervalEnum.DAILY],
      data: [
        {
          data: [0.3, 0.35, 0.57, 0.2, 0.24, 0.25, 0.37],
          label: 'Demand curve',
        },
        {
          data: [0.08, 0.5, 1, 0.55, 0.2, 0.1, 0.1],
          label: 'Solar yield curve',
        },
      ],
    },
    [GridDataIntervalEnum.MONTHLY]: {
      labels: consumptionLabelsMapping[GridDataIntervalEnum.MONTHLY],
      data: [
        {
          data: [0.3, 0.35, 0.57, 0.2, 0.24, 0.25, 0.37, 0.3, 0.35, 0.57, 0.2, 0.24],
          label: 'Demand curve',
        },
        {
          data: [0.08, 0.5, 1, 0.55, 0.2, 0.1, 0.1, 0.08, 0.5, 1, 0.55, 0.2],
          label: 'Solar yield curve',
        },
      ],
    },
  },
};
