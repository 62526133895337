import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from "../auth/auth.service";
import { TokenService } from "../auth/token.service";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

export const PATHS_WHITE_LIST = [
  '/auth/register/email/verify',
  '/auth/login/email/verify',
  '/auth/register',
  '/auth/login',
];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    if (this.authService.isAuthenticated()) {
      request = this.addToken(request, this.tokenService.getJwtToken());
    }
    const itFromWhiteList = PATHS_WHITE_LIST.some(page => this.router.url.startsWith(page))
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !itFromWhiteList) {
          this.tokenService.removeToken();
          this.router.navigate(['auth', 'login'])
        }
        return throwError(error);
      }),
    );
  }

  private addToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
  }
}
