import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from "ngx-device-detector";
import { TranslateService } from "@ngx-translate/core";
import { LocalizationService } from "./services/localization.service";
import { SimulationService } from "./services/simulation.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'simulation-angular-app';
  isMobile = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private localizationService: LocalizationService,
    private simulationService: SimulationService,
  ) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
  }

  async ngOnInit(): Promise<void> {
    this.isMobile = this.deviceService.isMobile();
    this.localizationService.setLocalization();
    await this.simulationService.currentSimulation;
  }
}
