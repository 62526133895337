import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-simulation-status',
  templateUrl: './simulation-status.component.html',
  styleUrls: ['./simulation-status.component.scss']
})
export class SimulationStatusComponent implements OnInit {
  runningMode = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.runningMode = this.route.snapshot.paramMap.get('mode');
  }

  getTitle(): string {
    if (this.runningMode === 'capex-opex') {
      return 'CAPEX OPEX';
    }
    if (this.runningMode === 'consumers') {
      return 'Load demand';
    }
    return 'Simulation';
  }

  navigateTo(): void {
    if (this.runningMode === 'capex-opex') {
      this.router.navigate(['capex-dashboard']);
      return;
    }

    if (this.runningMode === 'consumers') {
      this.router.navigate(['grid-data-dashboard']);
      return;
    }
    this.router.navigate(['simulations']);
  }

}
