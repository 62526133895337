import { Simulation } from "../models/simulation";

// export const sampleRes: { msg: string, sim: Simulation }  = {
//     "msg": "simulation created",
//     "sim": {
//         "id": "490f29ec-429b-4233-9d68-c9fb1f8ad6fe",
//         "createdAt": "2022-03-14T06:50:52.341179Z",
//         "updatedAt": "2022-03-14T06:50:52.341179Z",
//         "name": "Foo Sim #1",
//         "ownerEmail": "vincenz@dexgrid.io",
//         "revision": {
//             "id": "3adbe1ec-89ac-42cc-aea7-d8c95b00c3d5",
//             "createdAt": "2022-03-14T06:50:52.341179Z",
//             "updatedAt": "2022-03-14T06:50:52.341179Z",
//             "number": 1,
//             "name": "Revision #1",
//             "status": "COMPLETE",
//             "input": {
//                 "timeRange": {
//                     "start": "2021-10-21T10:00:00Z",
//                     "end": "2021-10-22T10:00:00Z"
//                 },
//                 "serviceArea": {
//                     "originPoint": {
//                         "lon": -66.6140616,
//                         "lat": 18.0110768
//                     },
//                     "originAddress": "Ponce, PR"
//                 },
//                 "intervalDuration": "1h0m0s",
//                 "consumers": [
//                     {
//                         "id": "810bd8a4-eb96-4cb4-9073-cb5220153c75",
//                         "name": "Residential Low Load",
//                         "appliances": [
//                             {
//                                 "id": "c4795cd5-52c8-4953-8950-61f6dbfb8ec3",
//                                 "description": "Air Conditioner",
//                                 "manufacturerName": "Samsung",
//                                 "modelName": "Chillo-Max",
//                                 "modelYear": 2015,
//                                 "maxLoadKWh": 4.2,
//                                 "modeSettings": {
//                                     "AUTO-COOL": {
//                                         "clientModeName": "Auto Cool",
//                                         "utilitization": 85
//                                     },
//                                     "AUTO-HEAT": {
//                                         "clientModeName": "Auto Heat",
//                                         "utilitization": 75
//                                     },
//                                     "OFF": {
//                                         "clientModeName": "Off",
//                                         "utilitization": 0
//                                     },
//                                     "ON-COOL": {
//                                         "clientModeName": "On Cool",
//                                         "utilitization": 70
//                                     },
//                                     "ON-HEAT": {
//                                         "clientModeName": "On Heat",
//                                         "utilitization": 60
//                                     }
//                                 }
//                             }
//                         ]
//                     }
//                 ]
//             },
//             "output": {
//                 "meta": {
//                     "weather": {
//                         "timeRange": {
//                             "start": "2021-10-21T10:00:00Z",
//                             "end": "2021-10-22T10:00:00Z"
//                         },
//                         "geoPoint": {
//                             "lon": -66.6140616,
//                             "lat": 18.0110768
//                         }
//                     },
//                     "financials": {
//                         "totalInvestment": 0,
//                         "itcDiscountAvail": 0,
//                         "recRevenueAvail": 0,
//                         "irrPct": 0,
//                         "npv": 0,
//                         "systemPct": 0,
//                         "epcPct": 0,
//                         "annualLoanPayment": 0,
//                         "avgAnnualOpex": 0,
//                         "avgAnnualNetIncomeGrowthPct": 0,
//                         "income": {
//                             "revenue": 0,
//                             "costOfSales": 0,
//                             "netIncome": 0,
//                             "growthPct": 0,
//                             "opex": 0,
//                             "totalExpenses": 0
//                         }
//                     }
//                 },
//                 "energy": {
//                     "produced": {
//                         "meta": {
//                             "tiers": null
//                         }
//                     },
//                     "consumed": {
//                         "meta": {
//                             "consumers": {
//                                 "totalCount": 120,
//                                 "types": {
//                                     "RESIDENTIAL": {
//                                         "count": 120,
//                                         "consumed": 88.2,
//                                         "consumedPct": 100
//                                     }
//                                 }
//                             }
//                         },
//                         "sim": {
//                             "meta": {
//                                 "total": {
//                                     "produced": 0,
//                                     "demanded": 88.2,
//                                     "consumed": 88.2,
//                                     "netSupply": 0
//                                 },
//                                 "average": {
//                                     "produced": 0,
//                                     "demanded": 3.6750000000000003,
//                                     "consumed": 3.6750000000000003,
//                                     "netSupply": 0
//                                 },
//                                 "min": {
//                                     "produced": 0,
//                                     "demanded": 0,
//                                     "consumed": 0,
//                                     "netSupply": 0
//                                 },
//                                 "max": {
//                                     "produced": 0,
//                                     "demanded": 14.7,
//                                     "consumed": 14.7,
//                                     "netSupply": 0
//                                 }
//                             },
//                             "events": [
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T10:00:00Z",
//                                         "end": "2021-10-21T11:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T11:00:00Z",
//                                         "end": "2021-10-21T12:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T12:00:00Z",
//                                         "end": "2021-10-21T13:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T13:00:00Z",
//                                         "end": "2021-10-21T14:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T14:00:00Z",
//                                         "end": "2021-10-21T15:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T15:00:00Z",
//                                         "end": "2021-10-21T16:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T16:00:00Z",
//                                         "end": "2021-10-21T17:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T17:00:00Z",
//                                         "end": "2021-10-21T18:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T18:00:00Z",
//                                         "end": "2021-10-21T19:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T19:00:00Z",
//                                         "end": "2021-10-21T20:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T20:00:00Z",
//                                         "end": "2021-10-21T21:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T21:00:00Z",
//                                         "end": "2021-10-21T22:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T22:00:00Z",
//                                         "end": "2021-10-21T23:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T23:00:00Z",
//                                         "end": "2021-10-22T00:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T00:00:00Z",
//                                         "end": "2021-10-22T01:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T01:00:00Z",
//                                         "end": "2021-10-22T02:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T02:00:00Z",
//                                         "end": "2021-10-22T03:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T03:00:00Z",
//                                         "end": "2021-10-22T04:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T04:00:00Z",
//                                         "end": "2021-10-22T05:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T05:00:00Z",
//                                         "end": "2021-10-22T06:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T06:00:00Z",
//                                         "end": "2021-10-22T07:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T07:00:00Z",
//                                         "end": "2021-10-22T08:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T08:00:00Z",
//                                         "end": "2021-10-22T09:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T09:00:00Z",
//                                         "end": "2021-10-22T10:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 }
//                             ]
//                         },
//                         "hour": {
//                             "meta": {
//                                 "total": {
//                                     "produced": 0,
//                                     "demanded": 88.2,
//                                     "consumed": 88.2,
//                                     "netSupply": 0
//                                 },
//                                 "average": {
//                                     "produced": 0,
//                                     "demanded": 3.6750000000000003,
//                                     "consumed": 3.6750000000000003,
//                                     "netSupply": 0
//                                 },
//                                 "min": {
//                                     "produced": 0,
//                                     "demanded": 0,
//                                     "consumed": 0,
//                                     "netSupply": 0
//                                 },
//                                 "max": {
//                                     "produced": 0,
//                                     "demanded": 14.7,
//                                     "consumed": 14.7,
//                                     "netSupply": 0
//                                 }
//                             },
//                             "events": [
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T10:00:00Z",
//                                         "end": "2021-10-21T11:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T11:00:00Z",
//                                         "end": "2021-10-21T12:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T12:00:00Z",
//                                         "end": "2021-10-21T13:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T13:00:00Z",
//                                         "end": "2021-10-21T14:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T14:00:00Z",
//                                         "end": "2021-10-21T15:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T15:00:00Z",
//                                         "end": "2021-10-21T16:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T16:00:00Z",
//                                         "end": "2021-10-21T17:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T17:00:00Z",
//                                         "end": "2021-10-21T18:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T18:00:00Z",
//                                         "end": "2021-10-21T19:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T19:00:00Z",
//                                         "end": "2021-10-21T20:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T20:00:00Z",
//                                         "end": "2021-10-21T21:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T21:00:00Z",
//                                         "end": "2021-10-21T22:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T22:00:00Z",
//                                         "end": "2021-10-21T23:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T23:00:00Z",
//                                         "end": "2021-10-22T00:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T00:00:00Z",
//                                         "end": "2021-10-22T01:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T01:00:00Z",
//                                         "end": "2021-10-22T02:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T02:00:00Z",
//                                         "end": "2021-10-22T03:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T03:00:00Z",
//                                         "end": "2021-10-22T04:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T04:00:00Z",
//                                         "end": "2021-10-22T05:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T05:00:00Z",
//                                         "end": "2021-10-22T06:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T06:00:00Z",
//                                         "end": "2021-10-22T07:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T07:00:00Z",
//                                         "end": "2021-10-22T08:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T08:00:00Z",
//                                         "end": "2021-10-22T09:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 },
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-22T09:00:00Z",
//                                         "end": "2021-10-22T10:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 0,
//                                         "consumed": 0,
//                                         "netSupply": 0
//                                     }
//                                 }
//                             ]
//                         },
//                         "day": {
//                             "meta": {
//                                 "total": {
//                                     "produced": 0,
//                                     "demanded": 14.7,
//                                     "consumed": 14.7,
//                                     "netSupply": 0
//                                 },
//                                 "average": {
//                                     "produced": 0,
//                                     "demanded": 14.7,
//                                     "consumed": 14.7,
//                                     "netSupply": 0
//                                 },
//                                 "min": {
//                                     "produced": 0,
//                                     "demanded": 0,
//                                     "consumed": 0,
//                                     "netSupply": 0
//                                 },
//                                 "max": {
//                                     "produced": 0,
//                                     "demanded": 14.7,
//                                     "consumed": 14.7,
//                                     "netSupply": 0
//                                 }
//                             },
//                             "events": [
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T10:00:00Z",
//                                         "end": "2021-10-22T10:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 }
//                             ]
//                         },
//                         "week": {
//                             "meta": {
//                                 "total": {
//                                     "produced": 0,
//                                     "demanded": 14.7,
//                                     "consumed": 14.7,
//                                     "netSupply": 0
//                                 },
//                                 "average": {
//                                     "produced": 0,
//                                     "demanded": 14.7,
//                                     "consumed": 14.7,
//                                     "netSupply": 0
//                                 },
//                                 "min": {
//                                     "produced": 0,
//                                     "demanded": 0,
//                                     "consumed": 0,
//                                     "netSupply": 0
//                                 },
//                                 "max": {
//                                     "produced": 0,
//                                     "demanded": 14.7,
//                                     "consumed": 14.7,
//                                     "netSupply": 0
//                                 }
//                             },
//                             "events": [
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T10:00:00Z",
//                                         "end": "2021-10-22T10:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 14.7,
//                                         "consumed": 14.7,
//                                         "netSupply": 0
//                                     }
//                                 }
//                             ]
//                         },
//                         "month": {
//                             "meta": {
//                                 "total": {
//                                     "produced": 0,
//                                     "demanded": 352.8,
//                                     "consumed": 352.8,
//                                     "netSupply": 0
//                                 },
//                                 "average": {
//                                     "produced": 0,
//                                     "demanded": 352.8,
//                                     "consumed": 352.8,
//                                     "netSupply": 0
//                                 },
//                                 "min": {
//                                     "produced": 0,
//                                     "demanded": 0,
//                                     "consumed": 0,
//                                     "netSupply": 0
//                                 },
//                                 "max": {
//                                     "produced": 0,
//                                     "demanded": 352.8,
//                                     "consumed": 352.8,
//                                     "netSupply": 0
//                                 }
//                             },
//                             "events": [
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T10:00:00Z",
//                                         "end": "2021-10-22T10:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 352.8,
//                                         "consumed": 352.8,
//                                         "netSupply": 0
//                                     }
//                                 }
//                             ]
//                         },
//                         "year": {
//                             "meta": {
//                                 "total": {
//                                     "produced": 0,
//                                     "demanded": 352.8,
//                                     "consumed": 352.8,
//                                     "netSupply": 0
//                                 },
//                                 "average": {
//                                     "produced": 0,
//                                     "demanded": 352.8,
//                                     "consumed": 352.8,
//                                     "netSupply": 0
//                                 },
//                                 "min": {
//                                     "produced": 0,
//                                     "demanded": 0,
//                                     "consumed": 0,
//                                     "netSupply": 0
//                                 },
//                                 "max": {
//                                     "produced": 0,
//                                     "demanded": 352.8,
//                                     "consumed": 352.8,
//                                     "netSupply": 0
//                                 }
//                             },
//                             "events": [
//                                 {
//                                     "timeRange": {
//                                         "start": "2021-10-21T10:00:00Z",
//                                         "end": "2021-10-22T10:00:00Z"
//                                     },
//                                     "energy": {
//                                         "produced": 0,
//                                         "demanded": 352.8,
//                                         "consumed": 352.8,
//                                         "netSupply": 0
//                                     }
//                                 }
//                             ]
//                         }
//                     }
//                 }
//             }
//         }
//     }
// };

export const sampleRes: { msg: string, sim: Simulation } = {
    "msg": "simulation created",
    "sim": {
      "id": "a35cb7f9-296c-4dc6-be64-e2ec403d4f50",
      "name": "Simulation Name",
      "ownerEmail": "test@gmail.com",
      "tags": [
        {
          "name": "v2.0",
          "revNumber": 5
        }
      ],
      "createdAt": "2019-08-24T14:15:22Z",
      "updatedAt": "2019-08-24T14:15:22Z",
      "company": {
        "name": "Energy Inc.",
        "email": "company@example.com",
        "address": "123 Main St.",
        "phone": "9876543210",
        "bio": "a long bio",
        "website": "www.example.com",
        "logoUrl": "http://us.gcp.com/12345abcdef"
      },
      "businessPlan": {
        "investmentTypes": [
          "EQUITY",
          "LOAN_EQUITY"
        ],
        "businessModel": "We plan to sell the electricity generated at our site at $0.21 per kWh",
        "customerAcq": "Providing affortable, reliable energy to our neighbors",
        "problem": "The unstable expirience that the community has with the current grid",
        "solution": "Bring a stable expirience to the community",
        "solutionHow": "A microgrid based on solar PV supplemented with wind and backed up with propane",
        "logoUrl": "http://us.gcp.com/12345abcdef"
      },
      "latestRevision": {
        "id": "a35cb7f9-296c-4dc6-be64-e2ec403d4f50",
        "number": 1,
        "name": "Revision #1",
        "status": "PENDING",
        "input": {
          "name": "New sim",
          "timeRange": {
            "start": "2021-06-08T10:00:00Z",
            "end": "2021-06-10T10:00:00Z"
          },
          "intervalDuration": {
            "years": 0,
            "months": 0,
            "days": 1,
            "hours": 0,
            "minutes": 0
          },
          "serviceArea": {
            "originAddress": "San Juan, PR",
            "originPoint": {
              "lat": 40.5274351,
              "lon": -74.5156657
            },
            "radiusKm": 15
          },
          "consumers": [
            {
              "id": "a35cb7f9-296c-4dc6-be64-e2ec403d4f50",
              "quantity": 5
            }
          ],
          "energy": {
            "generation": [
              {
                "id": "54f8006f-f37b-4c8d-9cab-4f34f58f3ff6",
                "name": "Solar PV",
                "tier": 1,
                "minGeneratedKw": 2200,
                "oneTimeCost": 150000,
                "perKwGroupCost": 10000,
                "perKwGroupKw": 1000,
                "perKwCost": 1.5,
                "perKwFuelCost": 1.1,
                "solarDegradePct": 20,
                "storageToGenRatio": 1.5,
                "systemLongevityYears": 10,
                "systemEquipmentLeaseTermYears": 10,
                "backupToGenRatio": 1.25
              },
              {
                "id": "9c1e86d3-dd4d-4087-816d-f51add67b36f",
                "name": "Wind turbine",
                "tier": 1,
                "minGeneratedKw": 2200,
                "oneTimeCost": 150000,
                "perKwGroupCost": 10000,
                "perKwGroupKw": 1000,
                "perKwCost": 1.5,
                "perKwFuelCost": 1.1,
                "solarDegradePct": 20,
                "storageToGenRatio": 1.5,
                "systemLongevityYears": 10,
                "systemEquipmentLeaseTermYears": 10,
                "backupToGenRatio": 1.25
              },
              {
                "id": "470d561b-7850-49e2-a728-06b47c2c952c",
                "name": "CHP/Cogen",
                "tier": 1,
                "minGeneratedKw": 2200,
                "oneTimeCost": 150000,
                "perKwGroupCost": 10000,
                "perKwGroupKw": 1000,
                "perKwCost": 1.5,
                "perKwFuelCost": 1.1,
                "solarDegradePct": 20,
                "storageToGenRatio": 1.5,
                "systemLongevityYears": 10,
                "systemEquipmentLeaseTermYears": 10,
                "backupToGenRatio": 1.25
              },
              {
                "id": "b09af149-1011-4585-8972-d971a810af07",
                "name": "Gas turbine",
                "tier": 1,
                "minGeneratedKw": 2200,
                "oneTimeCost": 150000,
                "perKwGroupCost": 10000,
                "perKwGroupKw": 1000,
                "perKwCost": 1.5,
                "perKwFuelCost": 1.1,
                "solarDegradePct": 20,
                "storageToGenRatio": 1.5,
                "systemLongevityYears": 10,
                "systemEquipmentLeaseTermYears": 10,
                "backupToGenRatio": 1.25
              },
              {
                "id": "1b97bcfd-66e3-4794-a1d1-c4f69dfb816e",
                "name": "Hydrogen turbine",
                "tier": 1,
                "minGeneratedKw": 2200,
                "oneTimeCost": 150000,
                "perKwGroupCost": 10000,
                "perKwGroupKw": 1000,
                "perKwCost": 1.5,
                "perKwFuelCost": 1.1,
                "solarDegradePct": 20,
                "storageToGenRatio": 1.5,
                "systemLongevityYears": 10,
                "systemEquipmentLeaseTermYears": 10,
                "backupToGenRatio": 1.25
              },
              {
                "id": "77b5ff65-9f3a-4ac6-8b81-0f390960fee9",
                "name": "CHP/Cogen",
                "tier": 2,
                "minGeneratedKw": 2200,
                "oneTimeCost": 150000,
                "perKwGroupCost": 10000,
                "perKwGroupKw": 1000,
                "perKwCost": 1.5,
                "perKwFuelCost": 1.1,
                "solarDegradePct": 20,
                "storageToGenRatio": 1.5,
                "systemLongevityYears": 10,
                "systemEquipmentLeaseTermYears": 10,
                "backupToGenRatio": 1.25
              },
              {
                "id": "32faa77e-32af-4026-b1e1-b419925a1b91",
                "name": "Engine",
                "tier": 2,
                "minGeneratedKw": 2200,
                "oneTimeCost": 150000,
                "perKwGroupCost": 10000,
                "perKwGroupKw": 1000,
                "perKwCost": 1.5,
                "perKwFuelCost": 1.1,
                "solarDegradePct": 20,
                "storageToGenRatio": 1.5,
                "systemLongevityYears": 10,
                "systemEquipmentLeaseTermYears": 10,
                "backupToGenRatio": 1.25
              }
            ],
            "storage": [
              {
                "id": "449eac2b-5d8f-4678-b08f-0312ae6fc52a",
                "name": "Battery",
                "tier": 1,
                "minStorageKw": 2500,
                "maxUnitCapacityKw": 500,
                "oneTimeCost": 150000,
                "perUnitGroupCost": 5000,
                "perUnitGroupUnits": 50,
                "perUnitCost": 1000,
                "perKwhCost": 2.1,
                "lossPerHourKw": 0.1,
                "peakUnitOutputKwh": 100
              }
            ]
          }
        },
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "output": {
          "meta": {
            "energy": {
              "total": {
                "produced": 8.75,
                "demanded": 8.5,
                "consumed": 8.5,
                "netSupply": 0.25
              }
            },
            "consumers": {
              "totalCount": 50,
              "types": {
                "RESIDENTIAL": {
                  "count": 30,
                  "usage": 8000
                },
                "COMMERCIAL": {
                  "count": 20,
                  "usage": 11000
                }
              }
            },
            "financials": {
              "totalInvestment": 1500000,
              "itcDiscountAvail": 250000,
              "recRevenueAvail": 250000,
              "irrPct": 35,
              "npv": 3100000,
              "systemPct": 75,
              "epcPct": 25,
              "annualLoanPayment": 75000,
              "avgAnnualOpex": 40000,
              "income": {
                "revenue": 5500000,
                "costOfSales": 500000,
                "netIncome": 3500000,
                "growthPct": 25,
                "opex": 1500000,
                "totalExpenses": 2000000
              }
            }
          },
          "energy": {
            "capacity": {
              "tiers": [
                {
                  "total": 900,
                  "generation": {
                    "total": 400,
                    "methods": [
                      {
                        "id": "a35cb7f9-296c-4dc6-be64-e2ec403d4f50",
                        "name": "Solar PV",
                        "kW": 400
                      }
                    ]
                  },
                  "storage": {
                    "total": 500,
                    "methods": [
                      {
                        "id": "a35cb7f9-296c-4dc6-be64-e2ec403d4f50",
                        "name": "Big Battery",
                        "kW": 500
                      }
                    ]
                  }
                },
                {
                  "total": 900,
                  "generation": {
                    "total": 400,
                    "methods": [
                      {
                        "id": "a35cb7f9-296c-4dc6-be64-e2ec403d4f50",
                        "name": "Solar PV",
                        "kW": 400
                      }
                    ]
                  },
                  "storage": {
                    "total": 500,
                    "methods": [
                      {
                        "id": "a35cb7f9-296c-4dc6-be64-e2ec403d4f50",
                        "name": "Big Battery",
                        "kW": 500
                      }
                    ]
                  }
                },
              ]
            }
          },
          "usage": {
            "sim": {
              "meta": {
                "energy": {
                  "average": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "min": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "max": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  }
                }
              },
              "data": [
                {
                  "timeRange": {
                    "start": "2021-06-08T10:00:00Z",
                    "end": "2021-06-10T10:00:00Z"
                  },
                  "energy": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "financials": {
                    "income": {
                      "revenue": 5500000,
                      "costOfSales": 500000,
                      "netIncome": 3500000,
                      "growthPct": 25
                    }
                  }
                }
              ]
            },
            "hour": {
              "RESIDENTIAL": {
                "meta": {
                  "energy": {
                    "average": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "min": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "max": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    }
                  }
                },
                "data": [
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                ]
              },
              "COMMERCIAL": {
                "meta": {
                  "energy": {
                    "average": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "min": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "max": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    }
                  }
                },
                "data": [
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                ]
              },
            },
            "day": {
              "RESIDENTIAL": {
                "meta": {
                  "energy": {
                    "average": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "min": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "max": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    }
                  }
                },
                "data": [
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                ]
              },
              "COMMERCIAL": {
                "meta": {
                  "energy": {
                    "average": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "min": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "max": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    }
                  }
                },
                "data": [
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                ]
              }
            },
            "month": {
              "RESIDENTIAL": {
                "meta": {
                  "energy": {
                    "average": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "min": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "max": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    }
                  }
                },
                "data": [
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                ]
              },
              "COMMERCIAL": {
                "meta": {
                  "energy": {
                    "average": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "min": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "max": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    }
                  }
                },
                "data": [
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                  {
                    "timeRange": {
                      "start": "2021-06-08T10:00:00Z",
                      "end": "2021-06-10T10:00:00Z"
                    },
                    "energy": {
                      "produced": 8.75,
                      "demanded": 8.5,
                      "consumed": 8.5,
                      "netSupply": 0.25
                    },
                    "financials": {
                      "income": {
                        "revenue": 5500000,
                        "costOfSales": 500000,
                        "netIncome": 3500000,
                        "growthPct": 25
                      }
                    }
                  },
                ]
              },
            },
            "year": {
              "meta": {
                "energy": {
                  "average": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "min": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "max": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  }
                }
              },
              "data": [
                {
                  "timeRange": {
                    "start": "2021-06-08T10:00:00Z",
                    "end": "2021-06-10T10:00:00Z"
                  },
                  "energy": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "financials": {
                    "income": {
                      "revenue": 600000,
                      "costOfSales": 1000000,
                      "netIncome": 1000000,
                      "growthPct": 25
                    }
                  }
                },
                {
                  "timeRange": {
                    "start": "2021-06-08T10:00:00Z",
                    "end": "2021-06-10T10:00:00Z"
                  },
                  "energy": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "financials": {
                    "income": {
                      "revenue": 2000000,
                      "costOfSales": 1200000,
                      "netIncome": 1500000,
                      "growthPct": 25
                    }
                  }
                },
                {
                  "timeRange": {
                    "start": "2021-06-08T10:00:00Z",
                    "end": "2021-06-10T10:00:00Z"
                  },
                  "energy": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "financials": {
                    "income": {
                      "revenue": 2500000,
                      "costOfSales": 1500000,
                      "netIncome": 2000000,
                      "growthPct": 25
                    }
                  }
                },
                {
                  "timeRange": {
                    "start": "2021-06-08T10:00:00Z",
                    "end": "2021-06-10T10:00:00Z"
                  },
                  "energy": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "financials": {
                    "income": {
                      "revenue": 3500000,
                      "costOfSales": 2100000,
                      "netIncome": 2700000,
                      "growthPct": 25
                    }
                  }
                },
                {
                  "timeRange": {
                    "start": "2021-06-08T10:00:00Z",
                    "end": "2021-06-10T10:00:00Z"
                  },
                  "energy": {
                    "produced": 8.75,
                    "demanded": 8.5,
                    "consumed": 8.5,
                    "netSupply": 0.25
                  },
                  "financials": {
                    "income": {
                      "revenue": 4100000,
                      "costOfSales": 2500000,
                      "netIncome": 3500000,
                      "growthPct": 25
                    }
                  }
                },
              ],
            }
          }
        },
      }
    }
  }