import { Injectable } from '@angular/core';
import { LoadProgressStatusEnum, ProgressStatusEnum } from "./progress.enum";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private _progressStatus$ = new BehaviorSubject<ProgressStatusEnum>(ProgressStatusEnum.LOAD_DEMAND);
  private _loadProgressStatus: LoadProgressStatusEnum = LoadProgressStatusEnum.PROJECT_MAPPING;
  /* **************************************************************************************************************** */
  // Property _progressStatus stores the progress state (for displaying on the progress dashboard).
  //  It is set depending on which the corresponding application steps
  // will be available to the user. For example:
  //
  // If the user has access to the form for entering information about consumers load demand, the following status should be set:
  // this.changeProgressStatus(ProgressStatusEnum.LOAD_DEMAND);
  //
  // etc:
  // this.changeProgressStatus(ProgressStatusEnum.CAPITAL_RAISING);
  // this.changeProgressStatus(ProgressStatusEnum.OPERATION_SIM);
  // this.changeProgressStatus(ProgressStatusEnum.LAUNCH);
  /* **************************************************************************************************************** */


  /*return subscription to progress status of current simulation, mocked in simulation.service*/
  get progressStatus$(): Observable<ProgressStatusEnum> {
    return this._progressStatus$.asObservable();
  }

  /*return the value of progress status of current simulation*/
  get progressStatus(): ProgressStatusEnum {
    return this._progressStatus$.value;
  }

  get loadProgressStatus(): LoadProgressStatusEnum {
    return this._loadProgressStatus;
  }

  constructor() { }


  /*method used for setting of progress status of current simulation (after getting from endpoint or after switching)*/
  changeProgressStatus(newStatus: ProgressStatusEnum): void {
    this._progressStatus$.next(newStatus);
  }

  changeLoadProgressStatus(newStatus: LoadProgressStatusEnum): void {
    this._loadProgressStatus = newStatus;
  }

  /* method resets progress status's value for new simulation's environment in the app*/
  resetProgress(): void {
    this._progressStatus$.next(ProgressStatusEnum.LOAD_DEMAND);
    this._loadProgressStatus = LoadProgressStatusEnum.PROJECT_MAPPING;
  }
}
