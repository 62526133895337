import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CompanyChanges, Profile, ProfileChanges } from "../models/profile";
import { API_URL } from "../constants";
import { profileMock } from "./profile-mock";
import { Observable, of } from "rxjs";
import { TokenService } from '../auth/token.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
  ) {
  }

  getAuthenticatedUser(): Observable<Profile> {
    // return this.http.get<Profile>(`${API_URL}/profile`);
    return of(profileMock);
  }

  saveProfileChanges(data: ProfileChanges) {
    // return this.http.put<Profile>(`${API_URL}/profile`, data);
    return of(profileMock);
  }

  saveCompanyChanges(data: CompanyChanges) {
    // return this.http.put<Profile>(`${API_URL}/profile/company`, data);
    return of(profileMock);
  }
}
