import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OldSimulation, SimulationShare } from "../models/oldSimulation";
import { API_URL } from "../constants";
import { TokenService } from "../auth/token.service";
import {
  BusinessPlan,
  Company,
  SimRevision,
  SimServiceArea,
  Simulation,
  SimulationInput,
  SimulationResponse
} from "../models/simulation";
import { Consumer } from "../models/consumer"
import { simulationCreatedResponse } from "../progress/consumers-load-data-mock";
import { sampleRes } from "./sampleRes";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {
  private _simServiceArea: SimServiceArea;
  private _SimulationList: Simulation[];
  private _currentSimulation: Simulation;
  existingConsumers: Consumer[];
  consumers: Consumer;

   // sim list
   private simulationList$: Subject<Simulation[]>;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
  ) {
    this.simulationList$ = new BehaviorSubject<Simulation[]>([]);
   }

  // return read-only sim list observable
  getSimulationList() {
    this.getSimulationsList();
    // console.log("getSimulationList" , this.simulationList$.pipe())
    return this.simulationList$.pipe();
  }

  get simServiceArea() {
    return this._simServiceArea;
  }

  set simServiceArea(area: SimServiceArea) {
    this._simServiceArea = area;
  }

  get currentSimulation() {
    // const latestSim = this.getLatestSimulation();
    // this._currentSimulation = this.getLatestSimulation();
    console.log("Grabbing _currentSimulation:")
    console.log(this._currentSimulation)
    return this._currentSimulation;
  }

  get SimulationList() {
    return this.simulationList$.pipe();
  }

  set currentSimulation(simulation: Simulation) {
    this._currentSimulation = simulation;
  }

  postSimulation(simInputs: SimulationInput) {
    const headers = this.headerService.POSTHeader();
    return this.http.post(`${API_URL}/simulations`, simInputs, { headers });
    // return of(simulationCreatedResponse);
  }

  updateSimulationOLD(id: string, data: OldSimulation) {
    return this.http.put(`${API_URL}/simulations/${id}`, data);
  }

  updateSimulation(id: string, data) {
    // return this.http.put(`${API_URL}/simulations/${id}`, data);
    const mockResponse = {
      ...sampleRes.sim,
      revision: sampleRes.sim.latestRevision
    };
    delete mockResponse.latestRevision;
    return of(mockResponse);
  }

  getSimulationById(id: string) {
    const headers = this.headerService.GETHeader();
    return this.http.get(`${API_URL}/simulations/${id}`, { headers });
  }

  deleteSimulation(id: string) {
    return this.http.delete(`${API_URL}/simulations/${id}`);
  }

  getSimRevision(simId: string, revId: string): Observable<SimRevision> {
    const headers = this.headerService.GETHeader();
    // return this.http.get<SimRevision>(`${API_URL}/simulations/${simId}/rev/${revId}`, { headers });
    return of(sampleRes.sim.latestRevision);
  }

  // getMockSimRevision(simId: string, revId: string): Observable<SimRevision> {
  //   return of(simulationCreatedResponse.sim.latestRevision);
  // }

  createSimShareLink(id: string, scope = 'READ') {
    const body = { scope };
    return this.http.post<SimulationShare>(`${API_URL}/simulations/${id}/shares`, body);
  }

  getSimShareLink(id: string, scope = 'READ') {
    return this.http.get<SimulationShare>(`${API_URL}/simulations/${id}/shares`, { params: { scope } });
  }

  getSimByShareTokenOLD(token: string) {
    return this.http.get<OldSimulation>(`${API_URL}/shared/simulations/${token}`);
  }

  getConsumersList() {
    const headers = this.headerService.POSTHeader();
    return this.http.get<Consumer[]>(`${API_URL}/consumers`, { headers });
    // return of(consumers);
  }

  getSimulationsList() {
    const headers = this.headerService.GETHeader();
     this.http.get<Simulation[]>(`${API_URL}/simulations`, { headers }).pipe(
       tap(simList => {
         this.simulationList$.next(simList);
       }),
       take(1),
     ).subscribe();
  }

  getLatestSimulation() {
    var simulationsLatest: Simulation;
    // this.simulationList$.subscribe( simulations => {
    //   simulationsLatest = simulations[simulations.length-1]
    // })
    return simulationsLatest;
  }

  postAnchorInfo(simId: string, companyInfo: Company, imageFile) {
    const headers = this.headerService.POSTHeader();
    console.log("companyInfo: " , companyInfo)
    const sendPackage = new FormData();
    const companyInfoInput = {
      "name": "ITC",
      "email": companyInfo.email,
      "address": "151 Calle San Francisco",
      "phone": "7474747474",
      "website": companyInfo.website,
      "contactPersonName": "JJJ Smith",
      "contactPersonEmail": "JJJ@itc.com",
      "contactPersonAddress": "151 Calee",
      "contactPersonPhone": "4389484848",
      "contactPersonWebsite": "www.jjj.com",
      "bio": companyInfo.bio,
      // "logo": imageFile
    }

    // TO DO: append to FormData sendPackage the companyInfo map
    sendPackage.append("name", companyInfoInput.name)
    sendPackage.append("email", companyInfoInput.email)
    sendPackage.append("logo", imageFile.file, "emailtop.png")
    console.log(`${API_URL}/simulations/${simId}/meta/company`)
    console.log(sendPackage)
    return this.http.put(`${API_URL}/simulations/${simId}/meta/company`, sendPackage, { headers });
    // return of({});
  }

  postInvestmentInfo(simId: string, businessPlan: BusinessPlan) {
    const headers = this.headerService.POSTHeader();
    const sendPackage = new FormData();
    const simBusinessPlan = {
      "investmentTypes": [
        "EQUITY"
      ],
      "businessModel": "Blah business model",
      "customerAcq": "Blah customer acquisition",
      "problem": "Blah problem",
      "solution": "Blah solution",
      "solutionHow": "Black solutuion how",
      "logo": "attach here"
    }
        // TO DO: append to FormData sendPackage the companyInfo map
    // sendPackage.append("investmentTypes", simBusinessPlan.investmentTypes)
    sendPackage.append("businessModel", simBusinessPlan.businessModel)
    sendPackage.append("customerAcq", simBusinessPlan.customerAcq)
    sendPackage.append("problem", simBusinessPlan.problem)
    sendPackage.append("solution", simBusinessPlan.solution)
    sendPackage.append("solutionHow", simBusinessPlan.solutionHow)
    console.log(simBusinessPlan)

    return this.http.put(`${API_URL}/simulations/${simId}/meta/businessplan`, sendPackage, { headers });
    // return of({});
  }

}



  //     "energy":
  //       {
  //       "generation": [
  //     {
  //       "id": "a35cb7f9-296c-4dc6-be64-eZec403d4650",
  //       "name": "Propane Engine",
  //       "tier": 1,
  //       "minGeneratedKw": 2200,
  //       "oneTimeCost": 150000,
  //       "perKwGroupCost": 10000,
  //       "perKwGroupKw": 1000,
  //       "perKwCost": 1.5,
  //       "perKwFuelCost": 1.1,
  //       "solarDegradePct": 20,
  //       "storageToGenRatio": 1.5,
  //       "systemLongevityYears": 10,
  //       "systemEquipmentLeaseTermYears": 10,
  //       "backupToGenRatio": 1.25,
  //     }
  //   ],
  //     "storage": [
  //     {
  //     "id": "a35cb7f9-296c-4dc6-be64-eZec403d4f50",
  //     "name": "Big Battery",
  //     "minStorageKw": 2500,
  //     "maxUnitCapacityKw": 500,
  //     "oneTimeCost": 150000,
  //     "perUnitGroupCost": 5000,
  //     "perUnitGroupUnits": 50,
  //     "perUnitCost": 1000,
  //     "perKwhCost": 2.1,
  //     "lossPerHourKw": 0.1,
  //     "peakUnitOutputKwh": 100
  //     },
  //   ],
  // },


      // var consumerID: string = "810bd8a4-eb96-4cb4-9073-cb5220153c75";
    // var consumers: Consumer

    // this.getConsumersList().subscribe(consumers => {
    //   consumerID = consumers[0].id;
    // }, console.error);

  //   const sim = {
  //     "name": "Simulation Name",
  //     "timeRange": {
  //       "start": "2021-02-01T10:00:00Z",
  //       "end": "2021-09-14T10:00:00Z"
  //     },
  //     "serviceArea": {
  //       "originPoint": {
  //         "lon": -66.61,
  //         "lat": 18.47
  //       },
  //       // "originAddress": "Ponce, PR",
  //       "radiusKm": 50
  //     },
  //     "intervalDuration": "1h",
  //     "consumers": [
  //       {
  //         "id": `${consumerID}`,
  //         "quantity": 35
  //       }
  //     ]
  // };