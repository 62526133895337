import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-simulation-running',
  templateUrl: './simulation-running.component.html',
  styleUrls: ['./simulation-running.component.scss']
})
export class SimulationRunningComponent implements OnInit, OnDestroy {
  progress: number = 0;
  interval: number = 0;
  runningMode = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.interval = setInterval((() => this.manageProgress()) as TimerHandler, 150);
    this.runningMode = this.route.snapshot.paramMap.get('mode');
  }

  manageProgress(): void {
    if (this.progress === 100) {
      clearInterval(this.interval);
      this.router.navigate(['simulation-status', { mode: this.runningMode }]);
    } else {
      this.progress = this.progress + 5;
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
