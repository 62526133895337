import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareSimulationService {
  sharingEnabled = false;
  sharingMode: 'view' | 'edit' = 'view';

  constructor() { }

  setShareSettings(enabled: boolean, mode: 'view' | 'edit') {
    this.sharingEnabled = enabled;
    this.sharingMode = mode;
  }
}
