import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GridDataDashboardComponent } from './grid-data-dashboard/grid-data-dashboard.component';
import { HeaderComponent } from './header/header.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { RunProgramComponent } from './run-program/run-program.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { BasicAuthInterceptor } from "./interceptors/basic-auth.interceptor";
import { MatCardModule } from "@angular/material/card";
import { SimulationsComponent } from './simulations/simulations.component';
import { SimulationStatusComponent } from './simulation-status/simulation-status.component';
import { ChartsModule } from "ng2-charts";
import { ShareDialogComponent } from './dialogs/share-dialog/share-dialog.component';
import { MatDialogModule } from "@angular/material/dialog";
import { ReachUsDialogComponent } from './dialogs/reach-us-dialog/reach-us-dialog.component';
import { SimulationRunningComponent } from './simulation-running/simulation-running.component';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { PageInfoDialogComponent } from './dialogs/page-info-dialog/page-info-dialog.component';
import { MatSliderModule } from "@angular/material/slider";
import { LocationDialogComponent } from './dialogs/location-dialog/location-dialog.component';
import { AgmCoreModule } from "@agm/core";
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { UserPreferencesDialogComponent } from './dialogs/user-preferences-dialog/user-preferences-dialog.component';
import { InputDialogComponent } from './dialogs/input-dialog/input-dialog.component';
import { MatStepperModule } from "@angular/material/stepper";
import { OperationsSimDashboardComponent } from './operations-sim-dashboard/operations-sim-dashboard.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { SharedSimulationComponent } from './shared-simulation/shared-simulation.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTreeModule } from "@angular/material/tree";
import { MatMenuModule } from "@angular/material/menu";
import { InviteDialogComponent } from './dialogs/invite-dialog/invite-dialog.component';
import { WhatNextDialogComponent } from './dialogs/what-next-dialog/what-next-dialog.component';
import { LoadDemandReviewDialogComponent } from './dialogs/load-demand-review-dialog/load-demand-review-dialog.component';
import { CustomConsumptionDialogComponent } from './dialogs/custom-consumption-dialog/custom-consumption-dialog.component';
import { CompletedDialogComponent } from './dialogs/completed-dialog/completed-dialog.component';
import { EditAssumptionsDialogComponent } from './dialogs/edit-assumptions-dialog/edit-assumptions-dialog.component';
import { NgxCurrencyModule } from "ngx-currency";
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { MatTabsModule } from "@angular/material/tabs";
import { LogoUploaderDialogComponent } from './dialogs/logo-uploader-dialog/logo-uploader-dialog.component';
import { ImageCropperModule } from "ngx-image-cropper";
import { ContactDialogComponent } from './dialogs/contact-dialog/contact-dialog.component';
import { FactsheetReviewDialogComponent } from './dialogs/factsheet-review-dialog/factsheet-review-dialog.component';
import { FinancialsReviewDialogComponent } from './dialogs/financials-review-dialog/financials-review-dialog.component';
import { SharedModule } from "./shared/shared.module";
import { SimulationReviewDialogComponent } from './dialogs/simulation-review-dialog/simulation-review-dialog.component';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProjectsManagerDialogComponent } from './dialogs/projects-manager-dialog/projects-manager-dialog.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    GridDataDashboardComponent,
    HeaderComponent,
    RunProgramComponent,
    SimulationsComponent,
    SimulationStatusComponent,
    ShareDialogComponent,
    ReachUsDialogComponent,
    SimulationRunningComponent,
    MessageDialogComponent,
    PageInfoDialogComponent,
    LocationDialogComponent,
    UserPreferencesDialogComponent,
    InputDialogComponent,
    OperationsSimDashboardComponent,
    DeleteDialogComponent,
    SharedSimulationComponent,
    SidebarComponent,
    InviteDialogComponent,
    WhatNextDialogComponent,
    LoadDemandReviewDialogComponent,
    CustomConsumptionDialogComponent,
    CompletedDialogComponent,
    LoadDemandReviewDialogComponent,
    EditAssumptionsDialogComponent,
    AccountSettingsComponent,
    LogoUploaderDialogComponent,
    ContactDialogComponent,
    FactsheetReviewDialogComponent,
    FinancialsReviewDialogComponent,
    SimulationReviewDialogComponent,
    ProjectsManagerDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDividerModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    HttpClientModule,
    MatCardModule,
    ChartsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSliderModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebase.apiKey,
      libraries: ['geometry'],
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    MatSlideToggleModule,
    FormsModule,
    ClipboardModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatSidenavModule,
    MatTreeModule,
    MatMenuModule,
    NgxCurrencyModule,
    MatTabsModule,
    ImageCropperModule,
    SharedModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
      defaultLanguage: 'en',
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
