import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { TokenService } from "../auth/token.service";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  headerTitle$ = new BehaviorSubject<string>('');

  set headerTitle(title: string) {
    this.headerTitle$.next(title);
  }

  constructor(
    private tokenService: TokenService,
  ) { }

  POSTHeader() {
    const authToken = this.tokenService.getJwtToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${authToken}`,
    });
    return headers
  }

  GETHeader() {
    const authToken = this.tokenService.getJwtToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    });
    return headers
  }
}
