<h1 mat-dialog-title>Delete simulation</h1>
<div mat-dialog-content>
  <p class="message-paragraph">
    Are you sure you want to delete {{data?.simulationName}} simulation?
  </p>
</div>
<div mat-dialog-actions class="dialog-footer">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onConfirm()">OK</button>
</div>
