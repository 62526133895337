<div class="invite-dialog-container">
  <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
  <div class="invite-dialog-title">
    Invite Someone to Join Project
  </div>
  <form [formGroup]="inviteForm" class="invite-dialog-form">
    <mat-form-field appearance="outline" class="email-field">
      <input matInput formControlName="email" required placeholder="Enter email of invited person">
      <mat-error *ngIf="inviteForm.controls.email.hasError('required')">
        Email is required
      </mat-error>
      <mat-error *ngIf="inviteForm.controls.email.value && inviteForm.controls.email.hasError('invalidEmail')">
        Incorrect email address
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="email-field">
      <input matInput formControlName="confirmEmail" required placeholder="Confirm email of invited person">
      <mat-error *ngIf="inviteForm.controls.confirmEmail.hasError('required')">
        Email is required
      </mat-error>
      <mat-error *ngIf="inviteForm.controls.confirmEmail.value && inviteForm.controls.confirmEmail.hasError('notMatch')">
        Those emails didn’t match
      </mat-error>
    </mat-form-field>
  </form>
  <div class="invite-dialog-form">

    <div class="link-container">
      {{link ? link : 'Please use the menu to select what your guest can do within your microgrid project.'}}
    </div>
    <mat-form-field appearance="outline" class="join-mode-field">
      <mat-select
        [(value)]="joinMode"
        panelClass="invite-dialog-select"
        (valueChange)="changeSharingMode()"
      >
        <mat-option value="view">
          can view
        </mat-option>
        <mat-option value="edit">
          can edit
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="invite-dialog-actions">
    <div
      class="copy-button"
      [cdkCopyToClipboard]="link"
      (cdkCopyToClipboardCopied)="changeCopyLinkTitle()"
    >
      <img
        src="../../../assets/copy-icon.png"
        class="copy-icon"
        alt="copy_icon"
      />
      <span>{{copyLinkButtonTitle}}</span>
    </div>
    <button mat-button class="cancel-button" (click)="closeDialog()">Cancel</button>
    <button mat-button class="send-button">Send invite</button>
  </div>
</div>
