<h1 mat-dialog-title>Reach Us</h1>
<div mat-dialog-content>
  <form [formGroup]="reachUsForm">
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" required (focus)="resetError()">
      <mat-error *ngIf="reachUsForm.controls.email.hasError('required')">
        Email is required
      </mat-error>
      <mat-error *ngIf="reachUsForm.controls.email.value && reachUsForm.controls.email.hasError('invalidEmail')">
        Incorrect email address
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Your message</mat-label>
      <textarea matInput formControlName="message" required></textarea>
      <mat-error *ngIf="reachUsForm.controls.message.hasError('required')">
        Message is required
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="dialog-footer">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="sendMessage()">Send</button>
</div>
