<mat-toolbar class="toolbar" *ngIf="showHeader$ | async">
  <div class="toolbar-container">
    <p class="header-title" *ngIf="headerTitle">{{headerTitle}}</p>
    <button mat-icon-button class="notifications-button">
      <mat-icon class="notifications-icon">notifications</mat-icon>
    </button>
    <div class="logo-button" [matMenuTriggerFor]="logoMenu">
      <img
        src="../../assets/LOGO.png"
        class="logo-button-title"
        alt="logo"
      />
      <div class="add-circle-container">
        <img
          src="../../assets/add-circle.png"
          class="add-icon"
          alt="add_logo"
        />
      </div>
    </div>
    <mat-menu #logoMenu="matMenu" xPosition="before" [class]="'logo-menu'">
      <div class="menu-item company-name" mat-menu-item>
        <div class="logo-button">
          <img
            src="../../assets/LOGO.png"
            class="logo-button-title"
            alt="logo"
          />
        </div>
        Company name
      </div>
      <div class="menu-item" mat-menu-item (click)="navigateToProfile()">
        <img
          class="settings-icon"
          src="../../assets/settings-icon.png"
          alt="logo"
        />
        Settings
      </div>
      <mat-divider class="block-divider"></mat-divider>
      <div class="menu-item logout-item" mat-menu-item (click)="logout()">
        <img
          class="logout-icon"
          src="../../assets/logout-icon.png"
          alt="logo"
        />
        Log out
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
