import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  valueControl: FormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InputDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    if (this.data.isStringType) {
      this.valueControl = new FormControl(this.data.value || '');
    } else {
      this.valueControl = new FormControl(this.data.value || 0, [Validators.min(0)]);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    if (this.valueControl.valid) {
      this.dialogRef.close({ success: true });

      if (this.data?.onConfirm) {
        this.data.onConfirm(this.valueControl.value);
      }
    }
  }

}
