import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API_URL } from "../constants";
import { TokenService } from "../auth/token.service";
import {
  BusinessPlan,
  Company,
  SimRevision,
  SimServiceArea,
  Simulation,
  SimulationInput,
  SimulationResponse
} from "../models/simulation";
import { HeaderService } from './header.service';
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { take, tap } from 'rxjs/operators';

import { sampleRes } from "./sampleRes";

@Injectable({
  providedIn: 'root'
})
export class CurrentsimulationService {

// Storage of states BehaviorSubjects
  // latestSimulation
  latestSimulation$ = new BehaviorSubject(sampleRes.sim)
  // latestSimulationID
  latestSimulationID$ = new BehaviorSubject(sampleRes.sim.id)
  // latestRevision
  latestRevision$ = new BehaviorSubject(sampleRes.sim.latestRevision)
  // latestRevisionID
  latestRevisionID$ = new BehaviorSubject(sampleRes.sim.latestRevision.id)

  // sim list
  private simulationList$: Subject<Simulation[]>;

  constructor(    
    private http: HttpClient,
    private tokenService: TokenService,
    private headerService: HeaderService
    ) { 
      this.simulationList$ = new BehaviorSubject<Simulation[]>([]);
    }

  // return read-only sim list observable
  getSimulationList() {
    this.getSimulationsList();
    // console.log("getSimulationList" , this.simulationList$.pipe())
    return this.simulationList$.pipe();
  }

// Output Methods
  // Get currentSimulation
  get currentSimulation() {
    return this.latestSimulation$.pipe();
  }
  // Get currentSimulationID
  get currentSimulationID() {
    return this.latestSimulationID$.pipe();
  }
  // Get currentRevision
  get currentRevision() {
    return this.latestRevision$.pipe();
  }
  // Get currentRevisionID
  get currentRevisionID() {
    return this.latestRevisionID$.pipe();
  }

// Retrieval Methods

  // Get Simulations
  getSimulationsList() {
    const headers = this.headerService.GETHeader();
     this.http.get<Simulation[]>(`${API_URL}/simulations`, { headers }).pipe(
       tap(simList => {
         console.log(simList)
         // Temp add to sims list for local testing ***********************
        //  simList.push(sampleRes.sim)
         this.simulationList$.next(simList);
         this.getLatestSimulation(simList);
       }),
       take(1),
     ).subscribe();
     return this.simulationList$
  }

    getLatestSimulation(simList) {
      const latestSim = simList[simList.length-1];                           // Parse out latest sim
      this.latestSimulation$.next(latestSim)                                // Store in latestSimulation
      this.latestSimulationID$.next(this.latestSimulation$.value.id)       // Store simID in latestSimulationID
      const headers = this.headerService.GETHeader();
      this.http.get<Simulation>(`${API_URL}/simulations/${this.latestSimulation$.getValue().id}`, { headers }).pipe(
        tap(latestSim => {
          this.latestSimulation$.next(latestSim)
        }),
        take(2),
      ).subscribe();
      this.getLatestRevision(this.latestSimulation$.value)
    }

    getLatestRevision(latestSim: Simulation) {
      const latestRev = latestSim.latestRevision
      this.latestRevision$.next(latestRev)                                          // Store Revision in latestRevision
      // console.log("latestRevision$" , this.latestRevision$.getValue())
      this.latestRevisionID$.next(latestRev.id)                               // Store in latestRevisionID
      // console.log("latestRevisionID$" , this.latestRevisionID$.getValue())
    }

}
