<mat-sidenav-container class="sidenav-container" *ngIf="showSidebar$ | async">
  <mat-sidenav
    #sidenav
    mode="side"
    opened
    class="sidenav"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
    [ngClass]="{'expanded': expandedMenu}"
    (click)="expandMenu()"
  >
    <div class="logo-container">
      <img
        src="../../assets/full-color-mark.png"
        class="sidebar-logo-image"
        alt="logo"
      />
      <img
        *ngIf="expandedMenu"
        src="../../assets/logo-text.png"
        class="sidebar-logo-text"
        alt="logo"
      />
    </div>
    <div class="nav-buttons-container">
      <div class="nav-button">
        <a class="nav-link" routerLink="/project-progress" routerLinkActive="active">
          <div class="grid-icon-container">
            <div class="rectangle rectangle-1"></div>
            <div class="rectangle rectangle-2"></div>
            <div class="rectangle rectangle-3"></div>
          </div>
          <span
            *ngIf="expandedMenu"
            class="nav-link-text"
          >
            Project progress
          </span>
        </a>
        <div class="tooltip" *ngIf="!expandedMenu">Project progress</div>
      </div>
      <mat-tree
        [dataSource]="dataSource"
        [treeControl]="treeControl"
        *ngIf="expandedMenu"
        class="tree-control"
      >
        <mat-tree-node
          *matTreeNodeDef="let node"
          matTreeNodePadding
          [matTreeNodePaddingIndent]="node.level === 2 ? 20 : 40"
          [ngClass]="{'available-node': isAvailableNode(node), 'normal-font': node.level > 0}"
        >
          <mat-divider [vertical]="true" class="tree-line-level-0"></mat-divider>
          <mat-divider [vertical]="true" class="tree-line-level-1"  *ngIf="node.level >= 1"></mat-divider>
          <div class="item-circle-out" [ngClass]="{'available': isAvailableNode(node)}" *ngIf="node.level === 0">
            <div class="item-circle-in" *ngIf="isAvailableNode(node)"></div>
          </div>
          <div class="item-circle-out level-1" *ngIf="node.level === 1">
          </div>
          <div
            class="node-name"
            [ngClass]="{'low-level': node.level === 2, 'clickable-node': node.path}"
            (click)="navigateToProgressItem(node)"
          >
            {{node.name}}
          </div>
        </mat-tree-node>
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
          [matTreeNodePaddingIndent]="node.level === 2 ? 20 : 40"
          [ngClass]="{'available-node': isAvailableNode(node)}"
        >
          <mat-divider [vertical]="true" class="tree-line-level-0"></mat-divider>
          <mat-divider [vertical]="true" class="tree-line-level-1" *ngIf="node.level >= 1"></mat-divider>
          <div class="item-circle-out" [ngClass]="{'available': isAvailableNode(node)}" *ngIf="node.level === 0">
            <div class="item-circle-in" *ngIf="isAvailableNode(node)"></div>
          </div>
          <div class="circles-container">
            <mat-icon class="icon-level-1" *ngIf="node.level === 1 && !treeControl.isExpanded(node)">add_circle_outline</mat-icon>
            <mat-icon class="icon-level-1" *ngIf="node.level === 1 && treeControl.isExpanded(node)">remove_circle_outline</mat-icon>
          </div>
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
            (click)="onNodeClick(node)"
          >
            {{node.name}}
            <mat-icon class="mat-icon-rtl-mirror" *ngIf="treeControl.isExpanded(node) && node.level === 0">
              expand_less
            </mat-icon>
          </button>
        </mat-tree-node>
      </mat-tree>
      <div class="nav-button">
        <a
          class="nav-link"
          (click)="onNavigate($event)"
        >
          <mat-icon class="info-icon">info_outline</mat-icon>
          <span
            *ngIf="expandedMenu"
            class="nav-link-text"
          >
            About us
          </span>
        </a>
        <div class="tooltip" *ngIf="!expandedMenu">About us</div>
      </div>
      <div class="nav-button">
        <a class="nav-link" (click)="openReachUsDialog($event)">
          <mat-icon class="email-icon">email_outline</mat-icon>
          <span
            *ngIf="expandedMenu"
            class="nav-link-text"
          >
            Contact
          </span>
        </a>
        <div class="tooltip" *ngIf="!expandedMenu">Contact us</div>
      </div>
    </div>
    <div class="bottom-button-container manager-button" (click)="openProjectsManagerDialog($event)">
      <mat-icon class="help-icon">inventory_2</mat-icon>
      <span
        *ngIf="expandedMenu"
        class="nav-link-text help"
      >
        Projects manager
      </span>
      <div class="tooltip" *ngIf="!expandedMenu">Projects manager</div>
    </div>
    <div class="bottom-button-container">
      <mat-icon class="help-icon">help_outline</mat-icon>
      <span
        *ngIf="expandedMenu"
        class="nav-link-text help"
      >
        Help
      </span>
      <div class="tooltip" *ngIf="!expandedMenu">Help</div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
