<div class="logo-uploader-container">
  <div class="nav-buttons-container">
    <mat-icon class="back-icon" *ngIf="showCropper" (click)="showCropper = false">west</mat-icon>
    <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
  </div>
  <img
    class="gallery-img"
    src="assets/gallery.png"
    alt="gallery"
    *ngIf="!showCropper"
  >

  <div class="image-cropper-container" *ngIf="showCropper">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1"
      [transform]="transform"
      [containWithinAspectRatio]="true"
      format="png"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>
  </div>

  <div class="zoom-container" *ngIf="showCropper">
    <mat-icon class="zoom-icon" (click)="zoomOut()">remove</mat-icon>
    <mat-slider
      [min]="1"
      [max]="2"
      [step]="0.1"
      [(ngModel)]="scale"
      (input)="onChangeScale($event)"
    ></mat-slider>
    <mat-icon class="zoom-icon" (click)="zoomIn()">add</mat-icon>
  </div>

  <div class="logo-info" *ngIf="!showCropper">
    Company logo<br>
    <span>A  logo will help people recognize your company.</span>
  </div>

  <button mat-button class="add-logo-button" *ngIf="!showCropper">
    Add your company logo
    <input type="file" (change)="fileChangeEvent($event)"/>
  </button>

  <button mat-button class="save-button" *ngIf="showCropper" (click)="saveLogo()">
    Save Image
  </button>
</div>
