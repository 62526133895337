export enum ProgressStatusEnum {
  LOAD_DEMAND,
  FINANCIALS,
  CAPITAL_RAISING,
  DEVELOPMENT,
  OPERATION_SIM,
  LAUNCH,
}

export const progressStatusesMapping = {
  [ProgressStatusEnum.LOAD_DEMAND]: 'Load demand',
  [ProgressStatusEnum.FINANCIALS]: 'Financials',
  [ProgressStatusEnum.CAPITAL_RAISING]: 'Capital raising',
  [ProgressStatusEnum.DEVELOPMENT]: 'Development',
  [ProgressStatusEnum.OPERATION_SIM]: 'Operations simulation',
  [ProgressStatusEnum.LAUNCH]: 'Launch',
};

export enum LoadProgressStatusEnum {
  PROJECT_MAPPING,
  CONSUMERS_INPUTS,
  PREVIEW
}
