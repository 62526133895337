import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { emailMatchValidator, validateEmail } from "../../utils";
import { ShareSimulationService } from "../../services/share-simulation.service";
import { SimulationService } from "../../services/simulation.service";
import { SimulationShare } from "../../models/oldSimulation";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss']
})
export class InviteDialogComponent implements OnInit {
  joinMode: 'view' | 'edit' = 'edit';
  link: string;
  inviteForm: FormGroup;
  copyLinkButtonTitle = 'Copy link';
  simulationShare: SimulationShare;
  sharingEnabled = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { simulationId: string },
    private dialogRef: MatDialogRef<InviteDialogComponent>,
    private formBuilder: FormBuilder,
    private shareSimulationService: ShareSimulationService,
    private simulationService: SimulationService,
  ) { }

  ngOnInit(): void {
    this.inviteForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, validateEmail()]],
      confirmEmail: ['', [Validators.required, emailMatchValidator('email')]],
    });

    if (!this.data?.simulationId) {
      this.sharingEnabled = this.shareSimulationService.sharingEnabled;
      this.joinMode = this.shareSimulationService.sharingMode;
    } else {
      this.simulationService.getSimShareLink(this.data.simulationId).subscribe(simulationShare => {
        this.simulationShare = simulationShare;
        this.sharingEnabled = true;
        this.link = simulationShare.link;
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  changeSharingMode(): void {
    this.link = this.joinMode === 'view' ?  'https://some-view-link' : 'https://some-edit-link';
  }

  changeCopyLinkTitle(): void {
    this.copyLinkButtonTitle = 'Copied';
  }

  save(): void {
    if (!this.data?.simulationId) {
      this.shareSimulationService.setShareSettings(this.sharingEnabled, this.joinMode);
    }
    this.dialogRef.close();
  }

  onEnableInput(event: MatSlideToggleChange): void {
    if (event.checked && !this.simulationShare) {
      this.simulationService.createSimShareLink(this.data.simulationId).subscribe(simulationShare => {
        this.simulationShare = simulationShare;
        this.sharingEnabled = true;
        this.link = simulationShare.link;
      });
    }
  }

}
